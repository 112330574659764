import React from 'react';
import PropTypes from 'prop-types';

const IconWrapperPropTypes = {
    icon: PropTypes.func.isRequired,
}

const IconWrapperDefaultProps = {}

export default function IconWrapper({ icon: Icon }) {
    return (
        <div className="text-center">
            <span className="rounded-circle bg-gradient-color-secondary-inverse px-3 py-3 bg-secondary d-inline-flex mb-3 justify-content-center align-items-center text-light">
                <Icon size={50} />
            </span>
        </div>
    )
}


IconWrapper.propTypes = IconWrapperPropTypes;
IconWrapper.defaultProps = IconWrapperDefaultProps;
