import React, { useMemo } from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField, RadioBoxes} from "../../components/forms";
import {Pages} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import useAuth from "../../components/hooks/useAuth";
import {roleOptions} from "../../components/users/usersUtils";
import {routeLabels} from "../../utils/routes";

export default function User() {
    const { userId } = useParams();
    const { user: loggedInUser } = useAuth();
    const isNew = userId === 'new';
    const { data: user, isLoading: isLoadingItem, error: itemsError } = useGet({ endpoint: !isNew ? `/users/${userId}`: '' });
    const navigate = useNavigate();

    return (
        <Pages.Centred
            width="xs"
            padded={false}
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.users, label: routeLabels.users },
                { href: '', label: isNew ? 'Creating user': user?.id },
            ]}
        >
            <PageHeader
                header={isNew ? 'Creating new user' : 'Editing User'}
                backUrl={routes.users}
            />
                {isLoadingItem && <Loading />}
                {(user || isNew) && (
                    <BackendForm
                        targetEndpoint={isNew ? '/users' : `/users/${userId}`}
                        targetMethod={isNew ? 'POST': 'PUT'}
                        initialValues={{
                            name: '',
                            ...(user || {}),
                        }}
                        enableReinitialize
                        onSuccess={(resp, { setFieldValue, values }) => {
                            const id = resp?.data?.id;
                            if (values.password) setFieldValue('password', '');
                            if (values.password_confirmation) setFieldValue('password_confirmation', '');
                            navigate(id ? routes.user(id) : routes.fields)
                        }}
                    >
                        <FloatingLabelField name="name" label="Name" />
                        <FloatingLabelField name="email" label="E-mail address" />
                        {isNew && (
                            <>
                                <FloatingLabelField name="password" label="Password" type="password" />
                                <FloatingLabelField name="password_confirmation" label="Confirm new password" type="password" />
                            </>
                        )}
                        <RadioBoxes
                            name="role"
                            label="Role"
                            options={Object.values(roleOptions)}
                            inline={false}
                        />

                        {loggedInUser?.is_admin
                            && (!user?.is_admin || user?.id === loggedInUser?.id)
                            && (
                                <div className="mt-5">
                                    <FloatingLabelField name="password" label="New password" type="password" />
                                    <FloatingLabelField name="password_confirmation" label="Confirm new password" type="password" />
                                </div>
                            )}
                    </BackendForm>
                )}
        </Pages.Centred>
    );
}
