
export const concepts = {
    UserDashboard: 'Dashboard',
    AppName: <span className="text-nowrap">MS Pharma</span>,
    Account: 'Account',
    PersonalInformation: 'Personal Information',
    Group: 'Team',
    Groups: 'Teams',
    Username: 'Username',
    GroupName: 'Lab name',
    GroupMember: 'Member',
    GroupMembers: 'Members',
    Subscription: 'Subscription',
    Photo51Slogan: <span><i>The</i> Plasmid Editing tool of 2022.</span>,
    Photo51Slogan2: 'The plasmid editor for researchers who keep pressing forward.',
}
