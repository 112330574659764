import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useField} from "formik";
import {ExclamationTriangle, PlusLg} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {RadioBoxes, FloatingLabelField} from "../forms";
import {Alert} from "react-bootstrap";
import Description from "../Description";
import {useCurrencies} from "../hooks/useCurrencies";

const emptyPrice = {
    name: '',
    amount: '',
    active: '1',
};

const PriceBuilderPropTypes = {}

const PriceBuilderDefaultProps = {}

export default function PriceBuilder({ name }) {
    const { baseCurrency } = useCurrencies();
    const [{ value },, { setValue }] = useField(name);
    return (
        <>
            <Button variant="outline-primary" className="mb-2" size="sm" onClick={() => setValue((value || []).concat([emptyPrice]))}>
                <PlusLg />
                New price
            </Button>
            {Array.isArray(value) && value.map((price, idx) => {
                const isMutable = !price.hasOwnProperty('is_mutable') || price.is_mutable;
                return (
                    <div key={price.id || `idx-${idx}`} className="bg-light p-2 border mb-2">
                        {!isMutable && <Description className="fw-bold"><ExclamationTriangle />This price cannot be edited anymore, because it is used.</Description>}
                        <div className="d-flex gap-1">
                            <div className="flex-grow-1 w-50">
                                <FloatingLabelField
                                    name={`${name}.${idx}.name`} label="Price name"
                                    disabled={!isMutable}
                                />
                            </div>
                            <div className="flex-grow-1 w-25">
                                <FloatingLabelField
                                    name={`${name}.${idx}.amount`} label={`Value (${baseCurrency})`} helperText="E.g. 254.20"
                                    disabled={!isMutable}
                                />
                            </div>
                            <div className="w-25">
                                <FloatingLabelField
                                    name={`${name}.${idx}.stock_mutation`} label="Stock mutatie" helperText="E.g. -10.00"
                                    disabled={!isMutable}
                                />
                            </div>
                        </div>
                        <RadioBoxes
                            name={`${name}.${idx}.active`}
                            label="Status"
                            options={[{ value: '0', label: 'Non-active' }, { value: '1', label: 'Active' }]}
                            inline
                        />
                    </div>
                )
            })}
        </>
    );
}

PriceBuilder.propTypes = PriceBuilderPropTypes;
PriceBuilder.defaultProps = PriceBuilderDefaultProps;
