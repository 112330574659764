import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useGet from "./useGet";

export function useItem(itemId) {
    const isNew = itemId === 'new';
    const { data: item, isLoading: isLoadingItem, error: itemsError } = useGet({ endpoint: !isNew ? `/items/${itemId}?_with[]=fields&_with[]=prices`: '' });
    return { item, itemsError, isLoadingItem };
}

export function useItems({ disabled } = {}) {
    const { data, error, isLoading } = useGet({ endpoint: disabled ? '' : '/items' });
    return { items: data, error, isLoading };
}


export function useItemGroups({ disabled } = {}) {
    const { data, error, isLoading } = useGet({ endpoint: disabled ? '' : '/item-groups' });
    return { itemsGroups: data, error, isLoading };
}
