import React, {useEffect} from 'react';
import BackendForm from "../components/forms/BackendForm";
import Pages from "../components/layout/Pages";
import FloatingLabelField from "../components/forms/FloatingLabelField";
import {routes} from "../utils";
import useAuth from "../components/hooks/useAuth";
import {useNavigate} from "react-router";
import {At, Lock} from "react-bootstrap-icons";
import {backendEndpoints} from "../libs/backendEndpoints";
import useTitle from "../components/hooks/useTitle";

export default function Login() {
    useTitle('Log in')
    const {saveToken, user, isLoading} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoading && user) navigate(routes.kiosk)
    }, [user, isLoading])

    return (
        <Pages.Centred width="xs">
            <BackendForm
                initialValues={{
                    email_or_username: '',
                    password: '',
                }}
                targetEndpoint={backendEndpoints.login}
                targetMethod="POST"
                onSuccess={({data}) => {
                    saveToken(data.token);
                    // Hard refresh, such that the login cookie read into the react state;
                    window.location.href = data.two_factor ? routes.twoFactorChallenge : routes.dashboard;
                }}
                footerContent={(
                    <div className="py-2">
                        <a href={routes.forgotPassword()}>Forgotten password?</a>
                    </div>
                )}
            >
                <h1 className="gradient-color mb-4 text-center">Login to Your Account</h1>
                <FloatingLabelField
                    name="email_or_username"
                    label="E-mail or username"
                    icon={<At />}
                    marginBottom
                />
                <FloatingLabelField
                    type="password"
                    name="password"
                    label="Password"
                    icon={<Lock />}
                />
            </BackendForm>
        </Pages.Centred>
    );
}
