import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CurrencyAmount from "../../layout/CurrencyAmount";

const RestAmountPropTypes = {}

const RestAmountDefaultProps = {}

export default function RestAmount({ diff, currencies }) {
    return (
        <div className="text-xl text-center">
            Please add another:&nbsp;
            <div className="fw-bold">
                {currencies.map((currency, idx) => {
                    return <React.Fragment key={currency}>
                        {idx > 0 && <div className="mx-3">or</div>}
                        <span className="text-primary">
                            <CurrencyAmount.Converted key={currency} baseAmount={diff} currency={currency} />
                        </span>
                    </React.Fragment>;
                })}
            </div>
        </div>
    );
}

RestAmount.propTypes = RestAmountPropTypes;
RestAmount.defaultProps = RestAmountDefaultProps;
