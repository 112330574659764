
export function hashMap(arr, key = 'id', transformer = (v) => v) {
  const map = {};
  for (let i = 0; i < arr.length; i += 1) {
    map[transformer(arr[i][key])] = arr[i];
  }
  return map;
}


export function indexMap(arr, key = 'id', transformer = (v) => v) {
  const map = {};
  for (let i = 0; i < arr.length; i += 1) {
    map[transformer(arr[i][key])] = i;
  }
  return map;
}


export function collect(arr, key, transformer = undefined) {
  const map = {};
  if (Array.isArray(arr)) {

    for (let i = 0; i < arr.length; i += 1) {
      const rawKey = arr[i][key];
      const k = transformer ? transformer(rawKey) : rawKey;
      if (k) {
        if (!map[k]) {
          map[k] = [];
        }
        map[k].push(arr[i]);
      }
    }
  }
  return map;
}
