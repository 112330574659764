import React from 'react';
import { useTable } from "react-table";
import { useNavigate } from "react-router";
import styles from "./Table.module.scss";
import cx from "classnames";

const TablePropTypes = {}

const TableDefaultProps = {}

export default function Table({ memoisedData, memoisedColumns, buildLink }) {
    const navigate = useNavigate()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: memoisedColumns, data: memoisedData })

    return (
        // apply the table props
        <div className={cx({ [styles.TableContainer]: true })}>
            <table
                {...getTableProps()}
                className={cx({ [`table table-striped ${styles.Table}`]: true, 'table-hover': typeof buildLink === 'function', })}
            >
                <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()}>{column.render('header')}</th>
                                ))}
                        </tr>
                    ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        const destination = buildLink && row.original && buildLink(row.original);
                        return (
                            // Apply the row props
                            <tr
                                {...row.getRowProps()}
                                onClick={() => {
                                    if (destination) navigate(destination)
                                }}
                            >
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        const cellProps = cell.getCellProps();
                                        return (
                                            <td {...cellProps} className={destination ? 'cursor-pointer': ''}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = TablePropTypes;
Table.defaultProps = TableDefaultProps;
