import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";

const DataRowPropTypes = {
    labelSize: PropTypes.number,
}

const DataRowDefaultProps = {
    labelSize: 3
}

export default function DataRow({ label, children, vertical, labelSize }) {
    if (vertical) {
        return (
            <div className="mb-3">
                <FormCheckLabel className="text-sm">{label}</FormCheckLabel>
                <div>{children || '-'}</div>
            </div>
        )
    }
    return (
        <Row>
            <Col sm={labelSize} className="fw-semibold">{label}</Col>
            <Col sm={12 - labelSize}>{children || '-'}</Col>
        </Row>
    );
}

DataRow.propTypes = DataRowPropTypes;
DataRow.defaultProps = DataRowDefaultProps;
