import React, { useState } from 'react';
import {Alert, ButtonGroup, Modal} from "react-bootstrap";
import { Formik } from "formik";
import { Cart } from "react-bootstrap-icons";
import KioskButton from "./KioskButton";
import OrderList from "./OrderList";
import {useKioskContext} from "./KioskContext";
import Description from "../../Description";
import CustomerModal from "./CustomerModal";
import {useModifyStockModal} from "../../../modalboxes/ModifyStockModal";
import {useReceiveStockModal} from "../../../modalboxes/ReceiveStockModal";
import LoggedInUser from "../../layout/LoggedInUser";
import useReturnItemModal from "../../../modalboxes/ReturnItemModal";
import CurrencyAmount from "../../layout/CurrencyAmount";
import KioskLocation from "./KioskLocation";
import PayOffDebtButton from "./PayOffDebtButton";
import ExchangeRates from "../ExchangeRates";
import KioskItems from "./KioskItems";
import MobileSlideMenu from "./MobileSlideMenu";
import Logo from "../../Logo";
import FullCreditModal from "./FullCreditModal";
import OpenCreditModal from "./OpenCreditModal";
import useAuth from "../../hooks/useAuth";
import {roleOptions} from "../../users/usersUtils";
import {paymentType} from "../../../libs/backendEnums";
import DailySalesButton from "./DailySalesButton";


function CheckoutButtons() {
    const { checkout: finaliseCheckout, totals, discountRate } = useKioskContext();
    const [openCreditModal, setOpenCreditModal] = useState();
    const [openCustomerModalPromise, setCustomerModalPromise] = useState();
    const [fullCreditModal, setFullCreditModal] = useState();
    const [showTotalModal, setTotalModal] = useState();
    const [error, setError] = useState();
    const [checkoutProcess, setCheckoutProces] = useState();

    const checkout = (process, data) => {
        setCheckoutProces(process);
        return new Promise((resolve, reject) => {
            setCustomerModalPromise({
                resolve: (data) => {
                    setError('');
                    resolve(data);
                },
                reject: (data) => {
                    setError('');
                    reject(data)
                },
            });
        }).then((customerData) => {
            const additionalData = {
                ...data,
                discount_rate: discountRate,
                subtotal: totals.subtotal,
                customer: customerData,
            };
            return finaliseCheckout(process, additionalData).then((resp) => {
                setCustomerModalPromise();
                setOpenCreditModal(false);
                setTotalModal({
                    total: totals.total,
                    order: resp.data?.data,
                    checkedOutAmount: data.checkedOutAmount,
                    checkedOutCurrency: data.checkedOutCurrency,
                });
            }).catch(e => setError(e.response?.data?.message || e.message))
        })
    }

    return (
        <>
            <ButtonGroup className="d-flex mx-2">
                <button
                    className="bg-gradient-color-secondary p-3 flex-grow-1 text-light border-0"
                    onClick={() => setFullCreditModal(true)}
                    disabled={totals.total === 0}
                    style={{ borderRadius: '7px 0 0 7px', marginRight: 2 }}
                >
                    <b className="text-uppercase">Full</b> payment
                </button>
                <button
                    className="bg-gradient-color-secondary p-3 flex-grow-1 text-light border-0"
                    onClick={() => setOpenCreditModal(true)}
                    disabled={totals.total === 0}
                    style={{ borderRadius: '0 7px 7px 0' }}
                >
                    On <b className="text-uppercase">Credit</b>
                </button>
            </ButtonGroup>
            {openCustomerModalPromise && (
                <CustomerModal
                    resolve={openCustomerModalPromise.resolve}
                    reject={openCustomerModalPromise.reject}
                    close={() => {
                        setCustomerModalPromise();
                        setError('');
                    }}
                    error={error}
                    allowNoCustomer={checkoutProcess === paymentType.FULL}
                />
            )}
            {fullCreditModal && <FullCreditModal checkout={checkout} setFullCreditModal={setFullCreditModal} />}
            {showTotalModal && (
                <Modal show onHide={() => setTotalModal(undefined)} size="sm">
                    <Modal.Header className="justify-content-center">
                        <Modal.Title className="text-center">New order registered!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Description className="text-xl text-center">
                            <div>The order ID is #{showTotalModal.order?.id}.</div>
                            <strong className="d-block my-4">
                                {Array.isArray(showTotalModal.checkedOutAmount) && showTotalModal.checkedOutAmount.reduce((a, v) => a + v, 0) > 0
                                    ? (
                                        <div className="text-success">Paid using &nbsp;
                                            {showTotalModal.checkedOutAmount.map((amount, idx) => amount ? (
                                                <div key={idx}>
                                                    {idx > 0 && <span>and </span>}
                                                    <CurrencyAmount amount={amount} currency={showTotalModal.checkedOutCurrency[idx]} />
                                                </div>
                                            ) : null)}
                                        </div>
                                    )
                                    : <div className="text-danger">No amount was paid.</div>}
                            </strong>
                        </Description>
                        <KioskButton onClick={() => setTotalModal(undefined)} title="Close" className="w-100" />
                    </Modal.Body>
                </Modal>
            )}
            {openCreditModal && <OpenCreditModal setOpenCreditModal={setOpenCreditModal} checkout={checkout} />}
        </>
    );
}

function ReceiveStockWrapper() {
    const { location } = useKioskContext();
    const { openForLocation: openReceiveModal, modal: receiveStockModal } = useReceiveStockModal({});
    return (
        <>
            <KioskButton
                className="bg-secondary"
                title="Manage shipment"
                onClick={() => openReceiveModal(location)}
                disabled={!location?.id}
            />
            {receiveStockModal}
        </>
    )
}

export default function Kiosk() {
    const { user } = useAuth();
    const { totals } = useKioskContext();
    const { open: openStockModal, modal: modifyStockModal } = useModifyStockModal({ inKiosk: true });
    const { open: openReturnModal, modal: returnModal } = useReturnItemModal({ inKiosk: true });

    return (
        <div className="d-flex align-items-stretch vh-100 flex-column bg-gray-800 text-light">
            <div
                className="bg-dark py-2 px-3 d-flex align-items-center justify-content-between gap-5"
                style={{ borderBottom: '1px solid #666' }}
            >
                <Logo />
                <div className="d-flex gap-3 align-items-center">
                    <ExchangeRates />
                    <KioskLocation />
                    <LoggedInUser />
                </div>
            </div>
            <MobileSlideMenu
                titles={[
                    <span className="d-inline-flex align-items-center gap-1"><Cart /> <CurrencyAmount amount={totals.total} /></span>,
                    <>Products</>,
                    'Management',
                ]}
            >
                <MobileSlideMenu.Tab index={0} className="d-flex flex-column bg-dark pb-2">
                    {[roleOptions.KIOSK.value, roleOptions.ADMIN.value].indexOf(`${user.role}`) > -1 && (
                        <>
                            <OrderList />
                            <CheckoutButtons />
                        </>
                    )}
                </MobileSlideMenu.Tab>
                <MobileSlideMenu.Tab index={1} className="flex-grow-1">
                    {[roleOptions.KIOSK.value, roleOptions.ADMIN.value].indexOf(`${user.role}`) > -1 && <KioskItems />}
                </MobileSlideMenu.Tab>
                <MobileSlideMenu.Tab index={2}>
                    <div className="d-flex flex-wrap flex-sm-nowrap flex-row flex-sm-column mt-2 pe-2">
                        {[roleOptions.KIOSK.value, roleOptions.ADMIN.value].indexOf(`${user.role}`) > -1 && (
                            <>
                                <PayOffDebtButton />
                                <KioskButton className="bg-secondary" title="Return item" onClick={openReturnModal} />
                            </>
                        )}
                        <KioskButton className="bg-secondary" title="Modify stock" onClick={openStockModal} />
                        <ReceiveStockWrapper />
                        <DailySalesButton />
                    </div>
                    {returnModal}
                    {modifyStockModal}
                </MobileSlideMenu.Tab>
            </MobileSlideMenu>
        </div>
    );
}
