import React from 'react';
import useAuth from "../hooks/useAuth";

export default function CurrencyAmount({ amount, showAll, currency: propsCurrency, seperator }) {
    const { user } = useAuth()
    const currency = propsCurrency || user?.organisation?.currency;
    let displayCurrency = currency;
    if (currency === 'EUR') displayCurrency = <span>&euro;</span>;
    if (currency === 'USD') displayCurrency = <span>US$</span>;
    if (currency === 'SRD') displayCurrency = <span>$RD</span>;

    return (
        <>
            <span className={showAll ? 'fw-semibold': ''}>
                {displayCurrency}
                &nbsp;
                {parseFloat(amount).toFixed(2)}
            </span>
            {showAll && user.organisation?.exchange_rates?.map((record, idx) => (
                <div key={record.id}>
                    <span style={{ opacity: 0.7 }}>{seperator}</span>
                    <CurrencyAmount
                        currency={record.currency}
                        amount={(amount * record.rate).toFixed(2)}
                    />
                </div>
            ))}
        </>
    );
}

CurrencyAmount.defaultProps = {
    seperator: 'or '
}

export function convertAmount(amount, user, currency, inverse = false) {
    const rate = user?.organisation?.currency === currency
        ? 1
        : user?.organisation?.exchange_rates?.find((r) => r.currency === currency)?.rate
    return inverse ? amount / rate : amount * rate;
}

function ConvertedAmount({ baseAmount, currency }) {
    const { user } = useAuth();
    return (
        <CurrencyAmount amount={convertAmount(baseAmount, user, currency)} currency={currency} />
    )
}


CurrencyAmount.Converted = ConvertedAmount;
