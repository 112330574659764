import React, {useState} from 'react';
import axios from "axios";
import {Field} from "formik";
import Pages from "../components/layout/Pages";
import useGet from "../components/hooks/useGet";
import BackendForm from "../components/forms/BackendForm";
import FloatingLabelField from "../components/forms/FloatingLabelField";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Description from "../components/Description";
import {Link} from "react-router-dom";
import {routes} from "../utils";
import {concepts} from "../libs/lang";
import {backendEndpoints} from "../libs/backendEndpoints";
import Header from "../components/Header";
import Loading from "../components/Loading";
import useAuth from "../components/hooks/useAuth";
import ProfilePicture from "../components/ProfilePicture";
import ImageUploader from "../components/forms/ImageUploader";
import PageHeader from "../components/layout/PageHeader";

function Dropzone({ userId, onSuccess }) {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isUploading, setUploading] = useState(false);

    const onChange = (event) => {
        setUploading(false);

        // Prevent double upload
        if (isUploading) {
            return undefined;
        }

        const fd = new FormData();
        fd.append('file', event.currentTarget.files[0])
        fd.append('user_id', userId)
        axios.post('/files', fd)
            .then(({ data }) => {
                setMessage(data?.data?.message || 'New profile picture uploaded!')
                if (typeof onSuccess === 'function') onSuccess();
            })
            .catch((e) => {
                setError(e.response.data?.message || 'Something went wrong...')
            })
            .finally(() => {
                setUploading(false)
            })
    };

    return (
        <>
            {isUploading && <Loading />}
            {error && typeof error === 'string' && <Alert variant="danger">{error}</Alert>}
            {message && typeof message === 'string' && <Alert variant="success">{message}</Alert>}

            <input
                name="files.0"
                type="file"
                value={undefined}
                disabled={isUploading}
                onChange={onChange}
            />
        </>
    )
}

const GroupsPropTypes = {}

const GroupsDefaultProps = {}

export default function AccountForm() {
    const { user, reloadUser } = useAuth();
    const { data, isLoading, error } = useGet({ endpoint: '/me' })

    return (
        <Pages.Centred
            width="xs"
            breadcrumbs={[
                { href: routes.dashboard, label: concepts.UserDashboard },
                { label: 'My Account' },
            ]}
        >
            <PageHeader header="My Account" />
            <Description>
                Edit your account details here. If you want to change your password. Please go to the&nbsp;
                <Link to={routes.forgotPassword(user.email)}>password reset</Link> page.
            </Description>
            {!isLoading}
            {error && <Alert>{error}</Alert>}
            {data && (
                <BackendForm
                    initialValues={data}
                    targetEndpoint={backendEndpoints.updateUser(data.id)}
                    targetMethod="PUT"
                >
                    <FloatingLabelField name="email" label="Email" helperText="You will have to verify your new email." />
                    <FloatingLabelField name="name" label="Name"/>

                    <div className="d-flex mt-5">
                        {data?.id && (
                            <ImageUploader
                                name="image"
                                label="Profile picture"
                                type="App\Models\User"
                                typeId={data.id}
                                onSuccess={reloadUser}
                                className="rounded-circle"
                            />
                        )}
                    </div>
                </BackendForm>
            )}
        </Pages.Centred>
    );
}

AccountForm.propTypes = GroupsPropTypes;
AccountForm.defaultProps = GroupsDefaultProps;
