import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CurrencyAmount from "../../layout/CurrencyAmount";
import moment from "moment";
import {standardDateTimeFormat} from "../../../utils/momentHelpers";
import {CurrencyExchange} from "react-bootstrap-icons";
import useAuth from "../../hooks/useAuth";

const MiniOrderTablePropTypes = {}

const MiniOrderTableDefaultProps = {}

export default function MiniPaymentsTable({ items }) {
    const { user } = useAuth();
    return (
        <table className="table table-sm table-light text-sm">
            <thead>
            <tr>
                <th>Value in {user.organisation.currency}</th>
                <th>Registered at</th>
                <th>Checked out currency</th>
            </tr>
            </thead>
            <tbody>
            {Array.isArray(items) && items.map((record) => {
                const rate = record.checked_out_amount / record.amount;
                return (
                    <tr key={record.id}>
                        <td>
                            <CurrencyAmount amount={record.amount} />
                        </td>
                        <td>
                            {moment(record.created_at).format(standardDateTimeFormat)}
                        </td>
                        <td>
                            <CurrencyAmount amount={record.checked_out_amount} currency={record.checked_out_currency} />
                            {rate !== 1 && (
                                <div>
                                    <CurrencyExchange /> 1 {user.organisation.currency} = {(record.checked_out_amount / record.amount).toFixed(4)} {record.checked_out_currency}
                                </div>
                            )}
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
}

MiniPaymentsTable.propTypes = MiniOrderTablePropTypes;
MiniPaymentsTable.defaultProps = MiniOrderTableDefaultProps;
