import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {ErrorMessage, Field} from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import {Eye, EyeFill, EyeSlash} from "react-bootstrap-icons";
import styles from './FloatingLabelField.module.scss';

const FloatingLabelFieldPropTypes = {
    autoFocus: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    marginBottom: PropTypes.bool,
}

const FloatingLabelFieldDefaultProps = {
    type: 'text',
    autoFocus: false,
    marginBottom: true,
}


export default function FloatingLabelField({ name, label, type, autoFocus, icon, helperText, required, disabled, className, afterText, marginBottom }) {
    const MB = marginBottom ? 'mb-2' : 'mb-0';
    const [forceTextType, setForceTextType] = useState(false);
    const id = label + name;

    const Icon = forceTextType ? Eye: EyeSlash;
    const showPassword = type === 'password' && (
        <Icon
            className={cx({
                [styles.ToggleViewingPassword]: true,
                'fs-4 gray-500': true,
            })}
            onClick={() => setForceTextType(!forceTextType)}
        />
    );

    const mainInput = (
        <FloatingLabel
            controlId={id}
            label={<div className={cx({
                "required-field": required,
            })}>{label}</div>}
            className={icon || helperText ? 'mb-0' : undefined}
        >
            <Field
                className={`form-control ${className} mb-0`}
                name={name}
                id={id}
                disabled={disabled}
                type={forceTextType ? 'text' : type}
                placeholder={label}
                autoFocus={autoFocus}
            />
        </FloatingLabel>
    )

    return (
        <div className={helperText ? "mb-0" : MB}>
            {(icon || afterText || showPassword) ? (
                <InputGroup className="mb-0">
                    {icon && (
                        <InputGroup.Text className="fs-4 gray-500">
                            {icon}
                        </InputGroup.Text>
                    )}
                    {mainInput}
                    {showPassword && <InputGroup.Text>{showPassword}</InputGroup.Text>}
                    {afterText && <InputGroup.Text>{afterText}</InputGroup.Text>}
                </InputGroup>
            ) : mainInput}
            {helperText && <div className="text-left text-sm gray-600">{helperText}</div>}
            <ErrorMessage name={name} className="text-danger" component="span" />
        </div>
    );
}

FloatingLabelField.propTypes = FloatingLabelFieldPropTypes;
FloatingLabelField.defaultProps = FloatingLabelFieldDefaultProps;
