import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field, useField} from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {inputClasses} from "./inputClasses";

function useMandatory({ options, mandatory, value, onChange, name }) {
    const optionDep = JSON.stringify(options);
    const doMandatory = () => {
        if (mandatory && (value === '' || typeof value === 'undefined') && Array.isArray(options) && options.length > 0) {
            onChange({ target: { value: options[0].value, name }});
        }
    }
    useEffect(() => {
        doMandatory();
    }, [optionDep, mandatory, value]);
    useEffect(() => {
        doMandatory();
    }, []);
}

const SelectPropTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

const SelectDefaultProps = {
    placeholder: '',
    onChange: undefined,
}

export default function SelectField({ options, name, label, placeholder, onChange, mandatory, disabled }) {
    const [{ value },, { setValue }] = useField(name);

    const localOnChange = e => {
        setValue(e.target.value)
        if (onChange) onChange(e)
    };

    useMandatory({ options, mandatory, value, onChange: localOnChange, name })

    return (
        <>
            <FloatingLabel controlId={label} label={label}>
                <Field
                    aria-label={label}
                    as="select"
                    name={name}
                    className="form-control"
                    onChange={localOnChange}
                    disabled={disabled}
                >
                    {placeholder && <option value="">{placeholder}</option>}
                    {options.map((o) => (
                        <option value={o.value} key={o.value}>{o.label}</option>
                    ))}
                </Field>
            </FloatingLabel>

            <ErrorMessage name={name} className="text-danger" component="span" />
        </>
    );
}

SelectField.propTypes = SelectPropTypes;
SelectField.defaultProps = SelectDefaultProps;

export function Select({ options, value, onChange, className, mandatory, name }) {
    useMandatory({ options, mandatory, value, onChange, name })

    return (
        <select value={value} onChange={onChange} className={className} name={name}>
            {!mandatory && <option value="">--- Select ---</option>}
            {options.map((option) => <option value={option.value} key={option.value}>{option.label}</option>)}
        </select>
    );
}

export function recordsToOptions(records) {
    return records?.map(record => ({ value: record.id, label: record.name || record.title })) || [];
}
