import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

export default function PanelSection({ children, className }) {
    return (
        <section className={`bg-light rounded-2 py-2 px-3 mb-3 ${className}`}>
            {children}
        </section>
    );
}

