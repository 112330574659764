import React from 'react';
import PropTypes from 'prop-types';

const HeaderPropTypes = {
    component: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
    badgeText: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

const HeaderDefaultProps = {
    badgeText: '',
    className: '',
}

export default function Header({ badgeText, children, component: Component, className, icon: Icon, id }) {
    return (
        <>
            {badgeText && (
                <div className="bg-light rounded-3 gray-600 text-sm d-inline-block py-2 px-4 mb-2">
                    {badgeText}
                </div>
            )}
            <div className={Icon ? 'd-flex justify-content-start align-items-center mb-3' : 'mb-3' }>
                {Icon && <Icon size={36} className="me-3" />}
                <Component className={className} id={id}>{children}</Component>
            </div>
        </>
    );
}

Header.propTypes = HeaderPropTypes;
Header.defaultProps = HeaderDefaultProps;
