export const backendEndpoints = {
    inviteUserToGroup: (groupId) => `/groups/${groupId}/invite-user`,
    updateUser: (userId) => "/users/"+userId,
    updateOrganisation: (orgId) => "/organisations/"+orgId+"?_with[]=exchange_rates",
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    createGroup: '/groups',
    getGroups: '/groups',
    login: '/create-token',
    signUp: '/register',
    deleteOrder: (id) => `/orders/${id}`,
    deleteSequence: (id) => `/stock-entries/sequences/${id}`,
    billingPortal: '/billing-portal',
    switchSubscription: '/user/subscriptions/switch',
}
