import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

export default function OptionBar({ children, stretch, negativeMargin }) {
    return (
        <div
            className={`${stretch ? 'd-flex align-content-stretch' : 'd-inline-flex'} align-items-stretch bg-primary gap-2 p-2 ${negativeMargin ? '' : 'rounded-3'} mb-3`}
            style={negativeMargin ? { margin: -16 } : {}}
        >
            {children}
        </div>
    );
}

OptionBar.defaultProps = {
    stretch: true,
}

function OptionItem({ label, children, transparent }) {
    return (
        <div className={`${transparent ? '' : 'bg-light p-2 rounded-2'} d-flex align-items-center`}>
            {label && <label className={`text-nowrap ms-1 me-3 ${transparent ? 'text-light fw-semibold': ''}`}>{label}</label>}
            {children}
        </div>
    );
}

OptionBar.Item = OptionItem;
