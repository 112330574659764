import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useOnHold from "../../hooks/useOnHold";
import Button from "react-bootstrap/Button";
import {DashLg, PlusLg} from "react-bootstrap-icons";
import {Modal} from "react-bootstrap";
import {inputClasses} from "../../forms/inputClasses";
import KioskButton from "./KioskButton";

const OrderListRowPropTypes = {}

const OrderListRowDefaultProps = {}

export default function OrderListRow({ item, decreaseItem, increaseItem, subtotal, priceObject, setQuantity, priceAmount, finalQty }) {
    const [isOpen, setIsOpen] = useState(false);
    const [qty, setQty] = useState(finalQty);
    const [error, setError] = useState('');
    const open = () => {
        setIsOpen(true);
        setQty(finalQty);
    }
    const holdProps = useOnHold({ callback: () => open() });

    useEffect(() => {
        if (Number.isInteger(parseInt(qty))) {
            setError('');
            setQuantity(item, priceObject, parseInt(qty));
        } else {
            setError('Please enter a number.');
        }
    }, [qty]);

    const close = () => setIsOpen(false);

    return (
        <tr>
            <td>
                <div className="fw-bold">{item.name}</div>
                <small>{priceObject.name}</small>
            </td>
            <td>
                <div className="d-flex align-items-center gap-2 unselectable position-relative" style={{ width: 120, zIndex: 2 }}>
                    <Button onClick={() => decreaseItem(item, priceObject)} variant="secondary">
                        <DashLg />
                    </Button>
                    <span className="flex-grow-1 text-center text-xxl cursor-pointer unselectable" {...holdProps}>
                        {finalQty < 10 && <span>{finalQty}</span>}
                        {finalQty >= 10 && finalQty < 100 && <span className="text-xl">{finalQty}</span>}
                        {finalQty >= 100 && <span className="text-lg">{finalQty}</span>}
                    </span>
                    <Button onClick={() => increaseItem(item, priceObject)} variant="secondary">
                        <PlusLg />
                    </Button>
                </div>
                {isOpen && (
                    <Modal show onHide={close} size="sm">
                        <Modal.Header closeButton>
                            Enter quantity for {item.name}
                        </Modal.Header>
                        <Modal.Body>
                            <input className={inputClasses.kioskSelectFullWidth} value={qty} type="number" min="1" onChange={e => setQty(e.target.value)} />
                            {error && <div className="text-danger">{error}</div>}
                            <KioskButton className="mt-3" onClick={close} title={<>Confirm &amp; Close</>} />
                        </Modal.Body>
                    </Modal>
                )}
            </td>
            <td>{priceAmount.toFixed(2)}</td>
            <td className="text-right">{subtotal.toFixed(2)}</td>
        </tr>
    );
}

OrderListRow.propTypes = OrderListRowPropTypes;
OrderListRow.defaultProps = OrderListRowDefaultProps;
