import React, {useCallback, useMemo, useRef, useState} from 'react';
import moment from "moment";
import {ButtonGroup, FormGroup} from "react-bootstrap";
import _ from "lodash";
import {OptionBar, Pages} from "../components/layout";
import {routes} from "../utils";
import useGet from "../components/hooks/useGet";
import {collect} from "../utils/hashMap";
import {computeStock, computeTotalStock} from "../components/stock/computeStock";
import {CartesianGrid, Legend, Tooltip, XAxis, YAxis, Line, LineChart } from "recharts";
import useAuth from "../components/hooks/useAuth";
import useTitle from "../components/hooks/useTitle";
import CurrencyAmount from "../components/layout/CurrencyAmount";
import {computeSubtotal} from "../components/pos/kiosk/KioskHelpers";
import Description from "../components/Description";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {defaultDatabaseDateFormat, standardDateFormat} from "../utils/momentHelpers";

function getFirstAndLastDate(dates, unitOfTime) {
    const o = { first: dates[0], last: dates[0] };
    for (let i = 1; i < dates.length; i++) {
        const date = dates[i];
        if (moment(date) < moment(o.first)) {
            o.first = date;
        }
        if (moment(date) > moment(o.last)) {
            o.last = date;
        }
    }
    o.diff = o.last != o.first ? moment(o.last).diff(moment(o.first), unitOfTime) : 0;
    return o;
}

const finalChanges = (perMonth, withDate) => Object.keys(perMonth)
    .sort((a, b) => moment(a) < moment(b) ? -1 : 1)
    .map(date => ({ date, ...perMonth[date] }));

const itemLabel = (item) => `${item.name}`;
const colors = [
    '#1abc9c',
    '#9b59b6',
    '#34495e',
    '#f1c40f',
    '#3498db',
    '#e67e22',
    '#e74c3c',
    '#ecf0f1',
    '#2ecc71',
    '#95a5a6',
];

const batchUnitOptions = {
    DAY: { value: 'DAY', label: 'Day', unitOfTime: 'days' },
    WEEK: { value: 'WEEK', label: 'Week', unitOfTime: 'weeks' },
    MONTH: { value: 'MONTH', label: 'Month', unitOfTime: 'months' },
}

function DashboardBlock({ children }) {
    return (
        <div className="bg-white shadow-sm border border-light p-3 mb-2">{children}</div>
    );
}

export default function Dashboard() {
    useTitle('Dashboard');
    const [batchUnit, setBatchUnit] = useState(batchUnitOptions.DAY.value);
    const [startDate, setStartDate] = useState(moment().subtract(2, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(3, 'days').format('YYYY-MM-DD'));

    const { user } = useAuth();
    const baseCurrency = user?.organisation?.currency;
    const currencies = [baseCurrency].concat(user?.organisation?.exchange_rates?.map(r => r.currency));
    const widthRef = useRef(window.innerWidth - 40);
    const { data: orders } = useGet({ endpoint: '/orders' });
    const { data: customers } = useGet({ endpoint: '/customers' });
    const { data: items } = useGet({ endpoint: '/items' });
    const { data: stockEntries } = useGet({ endpoint: '/stock-entries' });
    const { data: locations } = useGet({ endpoint: '/locations' });
    const { data: allOrderLineItems } = useGet({ endpoint: '/order-line-items' });
    const { data: allOrderCreditBalances } = useGet({ endpoint: '/order-credit-balances' });

    const allOrderLineItemsPerOrder = useMemo(() => collect(allOrderLineItems || [], 'order_id'), [allOrderLineItems]);

    const dateFilter = useCallback((o) => {
        const date = moment(o.created_at);
        return date >= moment(startDate) && date <= moment(endDate) && o.created_at;
    }, [startDate, endDate]);

    const { orderLineItems } = useMemo(() => {
        return { orderLineItems: Array.isArray(allOrderLineItems) ? allOrderLineItems.filter(dateFilter): [] };
    }, [allOrderLineItems, dateFilter]);
    const { orderCreditBalances } = useMemo(() => {
        return { orderCreditBalances: Array.isArray(allOrderCreditBalances) ? allOrderCreditBalances.filter(dateFilter): [] };
    }, [allOrderCreditBalances, dateFilter]);

    const transformer = useCallback((v) => {
        switch (batchUnit) {
            case batchUnitOptions.MONTH.value: return moment(v).format('MMM YYYY');
            case batchUnitOptions.DAY.value: return moment(v).format('ddd D MMM YYYY');
            case batchUnitOptions.WEEK.value: return 'week '+moment(v).startOf('W').format('W (D MMM YYYY)');
        }
    }, [batchUnit]);

    const { perData, first, diff } = useMemo(() => {
        return {
            perData: collect(orderLineItems, 'created_at', transformer),
            ...getFirstAndLastDate(orderLineItems.map(o => o.created_at), batchUnitOptions[batchUnit].unitOfTime),
        }
    }, [orderLineItems, batchUnit, transformer])
    const { perData: perDataBalances, first: firstBalance, diff: diffBalance } = useMemo(() => {
        return {
            perData: collect(orderCreditBalances, 'created_at', transformer),
            ...getFirstAndLastDate(orderCreditBalances.map(o => o.created_at), batchUnitOptions[batchUnit].unitOfTime),
        }
    }, [orderCreditBalances, batchUnit, transformer])

    const ordersWithDiscounts = useMemo(() => {
        return orders?.filter(order => order.discount_rate) || [];
    }, [orders]);

    // const salesPerProductData = useMemo(() => {
    //     const localPerData = _.cloneDeep(perData);
    //     for (let i = 0; i < diff + 1; i++) {
    //         const dateMoment = i ? moment(first) : moment(first).add(i, batchUnitOptions[batchUnit].unitOfTime);
    //         const date = transformer(dateMoment.toISOString());
    //         if (!localPerData.hasOwnProperty(date)) {
    //             localPerData[date] = [];
    //         }
    //
    //         // All product should have a value at every date
    //         const totalsPerProduct = {};
    //         items?.forEach((item) => totalsPerProduct[itemLabel(item)] = 0)
    //
    //         // Compute the total per item per date
    //         const perItem = collect(localPerData[date], 'item_id');
    //         for (const itemId in perItem) {
    //             const item = perItem[itemId][0]?.item;
    //             totalsPerProduct[itemLabel(item)] = perItem[itemId].reduce((acc, orderLineItem) => acc + orderLineItem.total_amount, 0)
    //         }
    //         localPerData[date] = totalsPerProduct
    //     }
    //
    //     return finalChanges(localPerData);
    // }, [perData, items, transformer, first, diff]);

    const salesPerCurrencyData = useMemo(() => {
        const localPerData = _.cloneDeep(perDataBalances);

        for (let i = 0; i < diffBalance + 3; i++) {
            const dateMoment = moment(firstBalance).add(i, batchUnitOptions[batchUnit].unitOfTime);
            const date = transformer(dateMoment.toISOString());
            if (!localPerData.hasOwnProperty(date)) {
                localPerData[date] = [];
            }

            // All currencies should have a value at every date
            const totalsPerCurrencyOnDate = {};
            currencies?.forEach((curr) => totalsPerCurrencyOnDate[curr] = 0)

            // Compute the total per item per date
            const perCurrency = collect(localPerData[date], 'checked_out_currency');
            for (const curr in perCurrency) {
                totalsPerCurrencyOnDate[curr] = perCurrency[curr].reduce((acc, record) => acc + parseFloat(record.checked_out_amount), 0)
            }
            localPerData[date] = totalsPerCurrencyOnDate
        }

        return finalChanges(localPerData);
    }, [perDataBalances, transformer, firstBalance, diffBalance, currencies]);

    const { customerInDebt } = useMemo(() => {
        const out = { customerInDebt: [] };
        if (Array.isArray(customers) && Array.isArray(orders) && Array.isArray(items)) {
            customers.forEach((customer) => {
                let totalDebt = 0;
                orders.forEach((order) => {
                    if (order.customer_id === customer.id) {
                        totalDebt += order.open_debt
                    }
                })
                if (totalDebt !== 0) {
                    out.customerInDebt.push({ customer, debt: totalDebt })
                }
            })

        }
        return out;
    }, [orders, customers, items]);

    const mutationsPerLocationPerItem = useMemo(() => {
        return computeStock({ locations, stockEntries, orderLineItems }, true);
    }, [locations, stockEntries, orderLineItems]);

    const quantitiesSoldPerProduct = useMemo(() => {
        const qtyPerProduct = {};
        for (let i = 0; i < orderLineItems.length; i += 1) {
            const { quantity: qty, item_id: itemId, item, price, total_amount } = orderLineItems[i];
            if (!qtyPerProduct.hasOwnProperty(itemId)) {
                qtyPerProduct[itemId] = { quantity: 0, item, total_amount: 0 };
            }
            qtyPerProduct[itemId].quantity += parseFloat(qty) * parseFloat(price.stock_mutation);
            qtyPerProduct[itemId].total_amount += parseFloat(total_amount);
        }
        return Object.values(qtyPerProduct);
    }, [orderLineItems]);

    const discountTotals = {
        subtotal: 0,
        discountSubtotal: 0,
        productSales: quantitiesSoldPerProduct.reduce((acc, val) => acc + val.total_amount, 0),
    };

    return (
        <Pages.Base className="px-3 mt-4 bg-light" padded>
            <OptionBar>
                <OptionBar.Item label="Dates">
                    <FormGroup>
                        <input className="form-control" value={startDate} type="date" onChange={e => setStartDate(e.target.value)} />
                    </FormGroup>
                    -
                    <FormGroup>
                        <input className="form-control" value={endDate} type="date" onChange={e => setEndDate(e.target.value)} />
                    </FormGroup>
                </OptionBar.Item>
                <OptionBar.Item label="Batch">
                    <FormGroup>
                        <select name="batch_unit" className="form-control" value={batchUnit} onChange={e => setBatchUnit(e.target.value)}>
                            {Object.values(batchUnitOptions).map((y) => <option key={y.value} value={y.value}>{y.label}</option>)}
                        </select>
                    </FormGroup>
                </OptionBar.Item>
                <OptionBar.Item label="Presets">
                    <ButtonGroup>
                        {[1, 0].map((deduct) => {
                            const unit = 'isoWeek';
                            const relevantMonth = moment().subtract(deduct, unit);
                            const bu = batchUnitOptions.WEEK.value;
                            const end = relevantMonth.endOf(unit).format(defaultDatabaseDateFormat);
                            const start = relevantMonth.startOf(unit).format(defaultDatabaseDateFormat);
                            return (
                                <Button
                                    variant={batchUnit === bu && startDate === start && endDate === end ? 'primary' : 'outline-primary'}
                                    onClick={() => {
                                        setBatchUnit(bu);
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                >
                                    {deduct === 0 && 'This week'}
                                    {deduct === 1 && 'Last week'}
                                </Button>
                            )
                        })}
                        {[3, 2, 1, 0].map((deduct) => {
                            const unit = 'month';
                            const relevantMonth = moment().subtract(deduct, unit);
                            const bu = batchUnitOptions.MONTH.value;
                            const end = relevantMonth.endOf(unit).format(defaultDatabaseDateFormat);
                            const start = relevantMonth.startOf(unit).format(defaultDatabaseDateFormat);
                            return (
                                <Button
                                    variant={batchUnit === bu && startDate === start && endDate === end ? 'primary' : 'outline-primary'}
                                    onClick={() => {
                                        setBatchUnit(bu);
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                >
                                    {relevantMonth.format('MMM YYYY')}
                                </Button>
                            )
                        })}
                    </ButtonGroup>
                </OptionBar.Item>
            </OptionBar>
            {/*<DashboardBlock>*/}
            {/*    <h4>Sales value ({user?.organisation.currency})</h4>*/}
            {/*    <Description>Sales denominated in the organisation's base currency to be able to compare better over time.</Description>*/}
            {/*    <LineChart width={Math.max(1000, widthRef.current)} height={350} data={salesPerProductData}>*/}
            {/*        <CartesianGrid strokeDasharray="3 3" />*/}
            {/*        <XAxis dataKey="date" />*/}
            {/*        <YAxis label={{ value: `${user?.organisation.currency}`, angle: -90 }} width={100}  />*/}
            {/*        <Tooltip />*/}
            {/*        <Legend layout="vertical" align="right" verticalAlign="top" margin={{ left: 20 }} wrapperStyle={{ paddingLeft: 10 }} />*/}
            {/*        {items?.map((item, i) => (*/}
            {/*            <Line*/}
            {/*                key={`${item.id}-${batchUnit}`}*/}
            {/*                animationDuration={0}*/}
            {/*                dataKey={itemLabel(item)}*/}
            {/*                stroke={colors[i % colors.length]}*/}
            {/*                strokeWidth={3}*/}
            {/*                dot={{ r: 10 }}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </LineChart>*/}
            {/*</DashboardBlock>*/}
            <div className="d-flex gap-2 flex-column">
                <div className="d-flex gap-2">
                    <DashboardBlock>
                        <h4>Net currencies change</h4>
                        <Description>
                            The net amount per currency that was added or removed from the organisation.
                            <br />
                            It only includes the actual amounts, so if orders on credit are excluded.
                        </Description>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Date</th>
                                {[salesPerCurrencyData[0]].map(({ date, ...currencies }) => (
                                    Object.keys(currencies).map(curr => <th key={curr}>{curr}</th>)
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {salesPerCurrencyData.map(({ date, ...data }) => {
                                const rowClassName =  date.indexOf('Sat') === 0 || date.indexOf('Sun') === 0 ? 'bg-light fw-semibold' : '';
                                return (
                                    <tr key={date} className={rowClassName}>
                                        <td className="text-nowrap">{date}</td>
                                        {Object.keys(data).map((curr) => {
                                            const amount = parseFloat(data[curr]);
                                            let className = 'text-nowrap ';
                                            if (amount > 0) className = 'text-success';
                                            if (amount < 0) className = 'text-warning';
                                            return (
                                                <td key={curr} className={className}>
                                                    {amount > 0 && '+'}
                                                    {amount < 0 && '-'}
                                                    {amount ? <CurrencyAmount amount={amount} currency={curr} /> : <>-</>}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </DashboardBlock>
                    <DashboardBlock>
                        <h4>Discounts</h4>
                        <Description>
                            The discounts given, so you can check
                        </Description>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Order value (without discount)</th>
                                <th>Discount %</th>
                                <th>Discount value ({baseCurrency})</th>
                                <th>Employee</th>
                                <th>Note</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ordersWithDiscounts.map((order) => {
                                const subtotal = computeSubtotal({ order_line_items: allOrderLineItemsPerOrder[order.id] || [] });
                                const discount = (subtotal * order.discount_rate);
                                discountTotals.subtotal += parseFloat(subtotal);
                                discountTotals.discountSubtotal  += discount;
                                return (
                                    <tr key={order.id}>
                                        <td><Link to={routes.order(order.id)}>{order.id}</Link></td>
                                        <td><CurrencyAmount amount={subtotal} /></td>
                                        <td>{order.discount_rate * 100}%</td>
                                        <td><CurrencyAmount amount={discount.toFixed(2)} /></td>
                                        <td>{order.created_by?.name}</td>
                                        <td>
                                            {subtotal == 0 && <i>All items were returned</i>}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>Totals</th>
                                <th>
                                    <CurrencyAmount amount={discountTotals.subtotal} />
                                </th>
                                <th></th>
                                <th>
                                    <CurrencyAmount amount={discountTotals.discountSubtotal} />
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </DashboardBlock>
                </div>
                <div className="d-flex gap-2">
                    <DashboardBlock>
                        <h4>Outstanding credit: <CurrencyAmount amount={customerInDebt.reduce((acc, rec) => acc + rec.debt, 0)} /></h4>
                        <Description>An overview of all customers with outstanding credit.</Description>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Customer name</th>
                                <th>Amount due</th>
                            </tr>
                            </thead>
                            <tbody>
                            {customerInDebt.map(row => (
                                <tr key={row.customer.id}>
                                    <td>
                                        <a href={routes.customer(row.customer.id)}>{row.customer.first_name} {row.customer.last_name}</a>
                                    </td>
                                    <td>
                                        <CurrencyAmount amount={row.debt} />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </DashboardBlock>
                    <DashboardBlock>
                        <h4 className="text-nowrap">
                            Product sales in {moment(startDate).format(standardDateFormat)} - {moment(endDate).format(standardDateFormat)}
                            :&nbsp;
                            <CurrencyAmount currency={baseCurrency} amount={discountTotals.productSales} />
                        </h4>
                        <Description>
                            Quantity of products sold within the given time period.
                            <br />
                            <b>Products without any sales are excluded.</b>
                        </Description>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>QTY</th>
                                <th>Total in {baseCurrency}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(quantitiesSoldPerProduct) && quantitiesSoldPerProduct?.map(({ quantity, item, total_amount }) => (
                                <tr key={item.id}>
                                    <td>
                                        <a href={routes.item(item.id)}>{item.name}</a>
                                    </td>
                                    <td>{quantity * -1}</td>
                                    <td>{total_amount}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </DashboardBlock>
                </div>
                <DashboardBlock>
                    <h4>Current stock summary</h4>
                    <table className="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Item</th>
                            {locations?.map(location => (
                                <React.Fragment key={location.id}>
                                    <th className="border-start-0"><small>Destined for</small><br/>{location.name}</th>
                                    <th><small>In stock at</small><br/>{location.name}</th>
                                </React.Fragment>)
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {items?.map((item) => (
                            <tr key={item.id}>
                                <th>{item.name}</th>
                                {locations?.map(location => {
                                    const mutations = mutationsPerLocationPerItem[location.id][item.id];
                                    const { current, upcoming } = computeTotalStock(mutations);
                                    return (
                                        <React.Fragment key={location.id}>
                                            <td>{upcoming || ''}</td>
                                            <td>{current < 50 ? <span className="fw-bold text-danger">{current}</span> : current}</td>
                                        </React.Fragment>
                                    )
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </DashboardBlock>
            </div>
        </Pages.Base>
    );
}
