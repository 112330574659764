import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {inputClasses} from "../forms/inputClasses";
import {Alert} from "react-bootstrap";
import Description from "../Description";
import Loading from "../Loading";
import useGet from "../hooks/useGet";

const SearchInputPropTypes = {}

const SearchInputDefaultProps = {}

export default function SearchInput({ Component, types }) {
    const [value, setValue] = useState('');
    const { data, error, isLoading } = useGet({ endpoint: value ? `/search?q=${value}&${types.map(t => `type[]=${t}`).join('&')}` : ''})

    return (
        <>
            <input
                placeholder="Search..."
                autoFocus
                className={inputClasses.queryBox}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
            {error && <Alert variant="danger">{error}</Alert>}
            {value.length === 0 && <Description>Please enter a search query...</Description>}
            {isLoading && <Loading />}
            {Array.isArray(data) && (data.length > 0 ? (
                data.map((i) => <Component item={i} setSearchQuery={setValue} />)
            ) : <Description>No results found</Description>)}
        </>
    );
}

SearchInput.propTypes = SearchInputPropTypes;
SearchInput.defaultProps = SearchInputDefaultProps;
