import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {Formik} from "formik";
import CurrencyAmount, {convertAmount} from "../../layout/CurrencyAmount";
import CurrencySelector from "../CurrencySelector";
import Description from "../../Description";
import PaidSection from "./PaidSection";
import KioskButton from "./KioskButton";
import {evaluateAmounts, TooLowAmount} from "./KioskHelpers";
import useAuth from "../../hooks/useAuth";
import {useKioskContext} from "./KioskContext";
import * as yup from "yup";
import {paymentType} from "../../../libs/backendEnums";

export default function OpenCreditModal({ setOpenCreditModal, checkout }) {
    const { user } = useAuth();
    const { totals, isValidNumber } = useKioskContext();

    const baseCurrency = user.organisation.currency;
    const currencies = [baseCurrency].concat(user?.organisation?.exchange_rates?.map(r => r.currency))

    return (
        <Modal show onHide={() => setOpenCreditModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Buying on credit: How much is paid now?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ currency: currencies, amount: currencies.map(() => '') }}
                    onSubmit={(values, { setSubmitting }) => {
                        checkout(paymentType.CREDIT, {
                            checkedOutAmount: values.amount,
                            checkedOutCurrency: values.currency,
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }}
                    validationSchema={yup.object({
                        currency: yup.array(yup.string()).required(),
                        amount: yup.array(
                            yup.number().min(0, 'Must be larger than zero.')
                        ).required()
                    })}
                >
                    {({ submitForm, values, handleChange }) => {
                        const { totalValid, tooMuch } = evaluateAmounts({
                            amounts: values.amount,
                            currencies: values.currency,
                            user,
                            isValidNumber,
                            totals,
                        });

                        return (
                            <div className="text-center">
                                <Description className="text-xl">
                                    The customer owes&nbsp;
                                    <b className="text-primary">
                                        <CurrencyAmount.Converted baseAmount={totals.total} currency={baseCurrency} />
                                    </b>
                                    &nbsp;in total.
                                </Description>
                                <hr />
                                <br />
                                <PaidSection name="amount" currencyName="currency" />
                                <Description className="text-xl">
                                    Together these amounts add up to: &nbsp;
                                    <br />
                                    <b className="text-primary">
                                        <CurrencyAmount.Converted baseAmount={totalValid} currency={baseCurrency} />
                                    </b>
                                    &nbsp;in total value.
                                </Description>
                                {tooMuch
                                    ? <TooLowAmount />
                                    : <KioskButton className="rounded-1 w-100 text-center" onClick={submitForm} title="Next: add customer information" />
                                }
                            </div>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
