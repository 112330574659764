import React from 'react';
import { Pages } from "../../components/layout";
import PageHeader from "../../components/layout/PageHeader";
import {routes} from "../../utils";
import {concepts} from "../../libs/lang";
import Alert from "react-bootstrap/Alert";
import BackendForm from "../../components/forms/BackendForm";
import {backendEndpoints} from "../../libs/backendEndpoints";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import useGet from "../../components/hooks/useGet";
import useAuth from "../../components/hooks/useAuth";
import {DynamicList} from "../../components/forms";
import {useField} from "formik";

function ExchangeRateRow({ item, index, name }) {
    const [{ value: currency }] = useField('currency');

    return (
        <div className="d-flex gap-2 align-items-center">
            <div>
                1 {currency} =
            </div>
            <FloatingLabelField name={`${name}.rate`} label="Rate" />
            <FloatingLabelField name={`${name}.currency`} label="ISO Currency name. E.g. EUR, SRD, USD" />
        </div>
    )
}

export default function Organisation() {
    const { user } = useAuth();
    const { data, isLoading, error } = useGet({
        endpoint: user.organisation_id ? `/organisations/${user.organisation_id}` : ''
    })

    return (
        <Pages.Centred
            width="xs"
            breadcrumbs={[
                { href: routes.dashboard, label: concepts.UserDashboard },
                { label: 'My Organisation' }
            ]}
        >
            <PageHeader header="My Organisation" />
            {!isLoading}
            {error && <Alert>{error}</Alert>}
            {data && (
                <BackendForm
                    initialValues={data}
                    targetEndpoint={backendEndpoints.updateOrganisation(data.id)}
                    targetMethod="PUT"
                >
                    <FloatingLabelField name="name" label="Name"/>
                    <FloatingLabelField name="currency" label="Currency" disabled />

                    <h2>Exchange rates</h2>
                    <DynamicList
                        name="exchange_rates"
                        Component={ExchangeRateRow}
                        addButton="Add currency"
                    />
                </BackendForm>
            )}
        </Pages.Centred>
    );
}
