import React from 'react';
import { getTotalsPerCurrency } from '../../utils/orders';
import { useCurrencies } from '../hooks/useCurrencies';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CurrencyAmount from "./CurrencyAmount";

const CurrencyAmountsBadgePropTypes = {}

const CurrencyAmountsBadgeDefaultProps = {}

export default function TotalsPerCurrencyBadge({ orders }) {
    const { currencies } = useCurrencies();
    const totalsPerCurrency = getTotalsPerCurrency(orders, currencies);

    const usedCurrenciesToPay = Object.keys(totalsPerCurrency).filter(curr => totalsPerCurrency[curr] > 0);

    return usedCurrenciesToPay.length > 1 ? (
        <>
            <OverlayTrigger
                // trigger={}
                overlay={(
                    <Tooltip id="used-currencies-badge">
                        Paid using:
                        {usedCurrenciesToPay.map((curr) => (
                            <div key={curr}>
                                <CurrencyAmount currency={curr} amount={totalsPerCurrency[curr]} />
                            </div>
                        ))}
                    </Tooltip>
                )}
                placement="right"
            >
                <div className="badge bg-success">
                    {usedCurrenciesToPay.length}
                </div>
            </OverlayTrigger>
        </>
    ) : null;
}

TotalsPerCurrencyBadge.propTypes = CurrencyAmountsBadgePropTypes;
TotalsPerCurrencyBadge.defaultProps = CurrencyAmountsBadgeDefaultProps;
