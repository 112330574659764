import React from 'react';
import * as yup from 'yup';
import {Alert, Modal} from 'react-bootstrap';
import { Formik } from 'formik';
import CurrencyAmount, {convertAmount} from "../../layout/CurrencyAmount";
import PaidSection from "./PaidSection";
import KioskButton from "./KioskButton";
import {useKioskContext} from "./KioskContext";
import useAuth from "../../hooks/useAuth";
import {ACCEPTABLE_MESSAGE, evaluateAmounts} from "./KioskHelpers";
import {paymentType} from "../../../libs/backendEnums";
import RestAmount from "./RestAmount";

export default function FullCreditModal({ setFullCreditModal, checkout }) {
    const { totals, isValidNumber } = useKioskContext();
    const { user } = useAuth();

    const baseCurrency = user.organisation.currency;
    const currencies = [baseCurrency].concat(user?.organisation?.exchange_rates?.map(r => r.currency))

    return (
        <Modal show onHide={() => setFullCreditModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><b>Full payment</b>: How much is paid?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ currencies, amounts: currencies.map(() => '') }}
                    onSubmit={(values, { setSubmitting }) => {
                        checkout(paymentType.FULL, {
                            baseAmountPaid: parseFloat(totals.total).toFixed(2),
                            checkedOutAmount: values.amounts,
                            checkedOutCurrency: values.currencies
                        }).finally(() => {
                            setSubmitting(false);
                        });
                        setFullCreditModal(false);
                    }}
                    validationSchema={yup.object({
                        currencies: yup.array(yup.string()).required(),
                        amounts: yup.array(
                            yup.number().min(0, 'Must be larger than zero.')
                        ).required()
                    })}
                >
                    {({ submitForm, values, isSubmitting }) => {
                        const { validAmounts, diff, tooMuch, tooLittle, totalValid } = evaluateAmounts({
                            amounts: values.amounts,
                            currencies: values.currencies,
                            user,
                            isValidNumber,
                            totals,
                        });
                        return (
                            <>
                                <div className="text-xl text-center mb-3">
                                    The customer owes&nbsp;
                                    <strong className="text-primary fw-bold">
                                        <CurrencyAmount.Converted baseAmount={totals.total} currency={baseCurrency} />
                                    </strong>
                                    &nbsp;in total.
                                </div>
                                <PaidSection name="amounts" currencyName="currencies" />
                                <br />
                                {tooLittle && <RestAmount currencies={currencies} diff={diff} />}
                                {tooMuch && (
                                    <Alert className="text-lg">
                                        <b>Too much</b>: the total value is &nbsp;
                                        <CurrencyAmount amount={totalValid} />
                                        {ACCEPTABLE_MESSAGE}
                                    </Alert>
                                )}
                                {validAmounts.length > 0 && !tooLittle && !tooMuch && (
                                    <KioskButton
                                        onClick={submitForm}
                                        title="Next: Customer information"
                                        className="w-100"
                                        isBusy={isSubmitting}
                                    />
                                )}
                            </>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
