import React, {useState, useEffect, useMemo, useContext} from 'react';
import PropTypes from 'prop-types';
import {DynamicList, FloatingLabelField, SelectField} from "../forms";
import {useField} from "formik";
import {recordsToOptions} from "../forms/SelectField";
import {hashMap} from "../../utils/hashMap";
import {CircleFill, DashCircle, DashCircleFill, Trash} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import CurrencyAmount from "../layout/CurrencyAmount";
import {Link} from "react-router-dom";
import {routes} from "../../utils";
import {computeSubtotal} from "../pos/kiosk/KioskHelpers";
import moment from "moment";
import {standardDateTimeFormat} from "../../utils/momentHelpers";

const Context = React.createContext({ viewOnly: false });

const widths = {
    first: 'w-33',
    second: 'w-33',
    third: 'w-15',
    fourth: 'w-10',
    fifth: '',
}

function OrderLineItemBadge({ item: orderLineItem, baseName, name, index }) {
    const [{ value },, { setValue }] = useField(baseName);
    const { viewOnly } = useContext(Context);

    const addRow = () => {
        setValue(value.concat([{
            item_id: orderLineItem.item_id,
            item: orderLineItem.item,
            price_id: orderLineItem.price_id,
            price: orderLineItem.price,
            quantity: (orderLineItem.quantity * -1).toFixed(2),
        }]));
    };

    return orderLineItem.id ? (
        <table className="w-100">
            <tr>
                <td className={widths.first}>Item name</td>
                <td className={widths.second}>Price</td>
                <td className={widths.third}>Quantity</td>
                <td className={widths.fourth}>Subtotal</td>
                {!viewOnly && (
                    <td className={widths.fifth} rowSpan={2}>
                        {parseFloat(orderLineItem.total_amount) > 0 && ( // You can only undo (i.e return) sales, which have a positive total. You cannot return returns, which have a negative totla
                            <Button onClick={() => addRow()}>
                                <DashCircleFill />
                            </Button>
                        )}
                    </td>
                )}
            </tr>
            <tr>
                <td>
                    <Link to={routes.item(orderLineItem.item.id)}>
                        {orderLineItem.item.name}
                    </Link>
                </td>
                <td>
                    <CurrencyAmount amount={orderLineItem.price?.amount} />
                    &nbsp;
                    [{orderLineItem.price?.name}]
                </td>
                <td>{orderLineItem.quantity}</td>
                <td>
                    <CurrencyAmount amount={orderLineItem.total_amount} />
                </td>
            </tr>
            <tr>
                <td colSpan={5} className="text-sm">
                    Created at: {moment(orderLineItem.created_at).format(standardDateTimeFormat)}
                </td>
            </tr>
        </table>
    ) : (
        <NewLine orderLineItem={orderLineItem} name={name} remove={() => {
            const newValue = [...value];
            newValue.splice(index, 1)
            setValue(newValue);
        }} />
    );
}

function NewLine({ orderLineItem, name, remove }) {
    const { viewOnly, discountRate } = useContext(Context);
    const { amount: priceAmount } = orderLineItem.price;

    let subTotal = priceAmount * orderLineItem.quantity;
    subTotal = (discountRate ? subTotal * (1 - parseFloat(discountRate)) : subTotal);

    return (
        <table>
            <tr>
                <td className={widths.first}>
                    <SelectField
                        name={`${name}.item_id`}
                        label="Item name"
                        disabled
                        options={recordsToOptions([orderLineItem.item])}
                    />
                </td>
                <td className={widths.second}>
                    <SelectField
                        name={`${name}.price_id`}
                        label="Price name"
                        disabled
                        options={recordsToOptions([orderLineItem.price])}
                    />
                </td>
                <td className={widths.third}>
                    <FloatingLabelField name={`${name}.quantity`} label="Quantity" type="number" />
                </td>
                <td className={widths.fourth}>
                    <CurrencyAmount amount={subTotal} />
                </td>
                {!viewOnly && (
                    <td className={widths.fifth}>
                        <Button onClick={remove}>
                            <Trash />
                        </Button>
                    </td>
                )}
            </tr>
        </table>
    )
}


export default function OrderLineItemsList({ name, viewOnly, discountRate }) {
    return (
        <Context.Provider value={{ viewOnly, discountRate }}>
            <DynamicList name={name} Component={OrderLineItemBadge} enableDelete={false} />
        </Context.Provider>
    );
}
