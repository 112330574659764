
const userPrefix = '/user'

export const sectionIds = {
    personalInfo: 'personalInfo',
    subscriptions: 'subscriptions',
    teams: 'teams',
    security: 'security',
}

export const routeLabels = {
    dashboard: 'Dashboard',
    locations: 'Locations',
    customers: 'Customers',
    customer: 'Customer',
    fields: 'Fields',
    itemGroup: 'Item Group',
    itemGroups: 'Item Groups',
    items: 'Items',
    orders: 'Orders',
    users: 'Users',
    user: 'User',
};

export const routes = {
    // START: before login routes
    login: '/login',
    signUp: '/sign-up',
    logout: '/logout',
    fields: '/fields',
    field: (id) => `/fields/${id}`,
    items: '/items',
    item: (id) => `/items/${id}`,
    itemGroups: '/item-groups',
    itemGroup: (id) => `/item-groups/${id}`,
    orders: '/orders',
    order: (id) => `/orders/${id}`,
    customers: '/customers',
    customer: (id) => `/customers/${id}`,
    users: '/users',
    user: (id) => `/users/${id}`,
    locations: '/locations',
    location: (id) => `/locations/${id}`,
    kiosk: '/kiosk',
    resetPassword: '/reset-password',
    completeAccount: (id, hash) => `/complete-account/${id}/${hash}`,
    completeAccountSuccess: '/complete-account-success',
    signUpSuccess: '/sign-up-success',
    forgotPassword: (email) => email ? `/forgot-password?email=${email}` : '/forgot-password',
    emailVerified: '/email-verified',
    // END: before login routes

    // START: logged in environment routes
    dashboard: '/dashboard',
    organisation: '/organisation',
    personalInformation: userPrefix+'#'+sectionIds.personalInfo,
    groups: userPrefix+'/groups',
    group: (id) => `${userPrefix}/groups/${id}`,
    subscriptions: userPrefix+'#'+sectionIds.subscriptions,
    security: userPrefix+'#'+sectionIds.security,
    reagents: userPrefix+'/reagents',
    photo51Cloud: userPrefix+'/cloud-apps/photo51',
    userSettings: userPrefix+'/account/edit',
    twoFactorAuthentication: userPrefix+'/enable-two-factor-authentication',
    twoFactorAuthenticationSuccess: userPrefix+'/two-factor-authentication-success',
    twoFactorChallenge: userPrefix+'/two-factor-challenge',
    groupsEdit: (id) => userPrefix+'/groups/edit/'+id,
    // END: logged in environment routes
}
