import React, { useState, useMemo } from 'react';
import { Alert, Modal } from "react-bootstrap";
import useGet from "../../hooks/useGet";
import { useKioskContext } from "./KioskContext";
import { collect } from "../../../utils/hashMap";
import KioskButton from "./KioskButton";
import CurrencyAmount from "../../layout/CurrencyAmount";
import Loading from "../../Loading";

const rowSize = 4;

export default function KioskItems() {
    const { data, isLoading: isLoadingButtons, error } = useGet({ endpoint: '/kiosk/buttons' });
    const { data: itemGroups, isLoading: isLoadingItemGroups, error: itemGroupsError } = useGet({ endpoint: '/item-groups?_with[]=image' });
    const isLoading = isLoadingButtons || isLoadingItemGroups;
    const { increaseItem } = useKioskContext();
    const [openModalItem, setOpenModalItem] = useState();
    const collection = collect(data || [], 'item_group_id');

    const close = () => setOpenModalItem();

    const itemButton = item => (
        <KioskButton
            title={item.name}
            cornerInfo={item.prices.length === 1 ? <CurrencyAmount amount={item.prices[0].amount} /> : 'Multiple prices'}
            key={item.id}
            imageUrl={item?.image?.url}
            onClick={() => {
                if (item.prices.length === 1) {
                    increaseItem(item, item.prices[0])
                    close();
                } else {
                    setOpenModalItem({ item });
                }
            }}
            maxWidth={375}
        />
    );

    const memoRows = useMemo(() => {
        const rows = [];
        if (Array.isArray(data)) {
            const buttons = data.filter(item => !item.item_group_id).map(itemButton);
            buttons.push(...(itemGroups || []).map((itemGroup) => (
                <KioskButton
                    key={`group-${itemGroup.id}`}
                    title={itemGroup.name}
                    cornerInfo={`${collection[itemGroup.id]?.length || 0} items`}
                    onClick={() => {
                        setOpenModalItem({ itemGroup });
                    }}
                    imageUrl={itemGroup?.image?.url}
                    className="bg-gradient-color-secondary-inverse rounded-3"
                    disabled={!collection[itemGroup.id]?.length > 0}
                    maxWidth={375}
                />
            )));
            for (let i = 0; i < buttons.length; i += rowSize) {
                rows.push(buttons.slice(i, i + rowSize));
            }
        }
        return rows;
    }, [data, itemGroups]);

    return (
        <div className="mt-2">
            {isLoading && <Loading />}
            {error && <Alert variant="error">{error}</Alert>}
            {itemGroupsError && <Alert variant="error">{itemGroupsError}</Alert>}
            {memoRows.map((buttonsInRow, idx) => (
                <div key={idx} className="d-flex gap-2 justify-content-start align-items-stretch flex-column flex-sm-row">
                    {buttonsInRow}
                </div>
            ))}
            {openModalItem && (
                <Modal show onHide={close} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {openModalItem.itemGroup ? 'Item group: '+openModalItem.itemGroup?.name : null}
                            {openModalItem.item ? 'Item: '+openModalItem.item?.name : null}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex gap-3">
                        {openModalItem.item?.prices.map(price => price.active ? (
                            <KioskButton
                                title={price.name}
                                cornerInfo={<CurrencyAmount amount={price.amount}/>}
                                key={price.id}
                                imageUrl={openModalItem.item?.image?.url}
                                onClick={() => {
                                    increaseItem(openModalItem.item, price)
                                    close();
                                }}
                            />
                        ): null)}
                        {openModalItem.itemGroup && collection[openModalItem.itemGroup.id]?.map(itemButton)}
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
}
