import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataRow } from "./layout";
import {standardDateTimeFormat} from "../utils/momentHelpers";

const EntityTimestampsPropTypes = {
    entity: PropTypes.shape({

    }).isRequired,
}

const EntityTimestampsDefaultProps = {}

export default function EntityTimestamps({ entity }) {
    const labelSize = 5;
    return entity ? (
        <>
            <DataRow label="Created at" labelSize={labelSize}>
                {moment(entity.created_at).format(standardDateTimeFormat)}
            </DataRow>
            <DataRow label="Created by" labelSize={labelSize}>
                {entity.created_by?.name}
            </DataRow>
            <DataRow label="Last modified at" labelSize={labelSize}>
                {entity.updated_at && moment(entity.updated_at).format(standardDateTimeFormat)}
            </DataRow>
        </>
    ) : null;
}

EntityTimestamps.propTypes = EntityTimestampsPropTypes;
EntityTimestamps.defaultProps = EntityTimestampsDefaultProps;
