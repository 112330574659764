import React, {useState, useRef, useContext, createContext} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Formik, Form as FormikForm, useFormikContext} from "formik";
import { post, get, put } from 'axios'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Loading from "../Loading";

const BackendFormContext = createContext({
    footerContent: null,
    error: undefined,
    feedback: undefined,
})

const FormPropTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    enableReinitialize: PropTypes.bool,
    showFooter: PropTypes.bool,
    targetMethod: PropTypes.oneOf(['POST', 'GET', 'PUT', 'DELETE']),
}

const FormDefaultProps = {
    enableReinitialize: false,
    onSuccess: undefined,
    onError: undefined,
    targetMethod: 'GET',
    showFooter: true,
    validationSchema: undefined,
}

export default function BackendForm({
    initialValues,
    children, targetEndpoint, targetMethod, onSuccess, enableReinitialize, footerContent,
    showFooter,
    onError,
    modifyValuesPreSubmit,
    validationSchema,
}) {
    const timeoutRef = useRef(null)
    const [error, setError] = useState('')
    const [feedback, setFeedback] = useState('')

    return (
        <BackendFormContext.Provider value={{ error, feedback, footerContent }}>
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    clearTimeout(timeoutRef)
                    setFeedback('')
                    setError('')

                    let method = get;
                    if (targetMethod === 'POST') {
                        method = post
                    } else if (targetMethod === 'PUT') {
                        method = put
                    }
                    try {
                        const finalValues = typeof modifyValuesPreSubmit === 'function' ? modifyValuesPreSubmit({ ...values }) : values;
                        method(targetEndpoint, { ...finalValues, token_name: 'web-app' })
                            .then((response) => {
                                if (onSuccess) onSuccess(response.data, { ...actions, values })
                                const { message } = response.data;
                                if (message) setFeedback(message)

                                // Remove the feedback after some time.
                                timeoutRef.current = setTimeout(() => {
                                    setFeedback('')
                                }, 3000)
                            })
                            .catch((e) => {
                                const data = e.response?.data;
                                const { message, errors } = data || {};

                                setError(message)
                                for (const errorsKey in errors) {
                                    const value = errors[errorsKey]
                                    actions.setFieldError(
                                        errorsKey,
                                        Array.isArray(value) ? value?.join(' ') : value,
                                    )
                                }
                                if (onError) onError(e.response)
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    } catch (e) {
                        setError(e.message);
                        actions.setSubmitting(false)
                    }
                }}
            >
                {(props) => (
                    <FormikForm onSubmit={props.handleSubmit} action="post">
                        {children}
                        {showFooter && <BackendForm.Footer />}
                    </FormikForm>
                )}
            </Formik>
        </BackendFormContext.Provider>
    );
}
function BackendFormFooter({ showError = true, marginTop = true, buttonText = 'Submit', className = '' }) {
    const { footerContent } = useContext(BackendFormContext);
    const { isSubmitting } = useFormikContext();
    return (
        <>
            <BackendFormErrorFeedback showError={showError} />
            <div className={cx({
                'd-flex justify-content-end' : true,
                'mt-5' : marginTop,
            })}>
                {footerContent && (
                    <div className="me-2 flex-grow-1">{footerContent}</div>
                )}
                <Button
                    className={cx({
                        "ps-5": true,
                        "pe-5": !isSubmitting,
                        "pe-3": isSubmitting,
                        [className]: !!className,
                    })}
                    size="lg"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                >
                    {buttonText}
                    {isSubmitting && <Loading />}
                </Button>
            </div>
        </>
    )
}
function BackendFormErrorFeedback({ showError }) {
    const { error, feedback } = useContext(BackendFormContext);

    return <>
        {showError && error ? <Alert variant="danger">{error}</Alert> : null}
        {feedback && <Alert variant="success">{feedback}</Alert>}
    </>;
}
BackendForm.Footer = BackendFormFooter;
BackendForm.Feedback = BackendFormErrorFeedback;
BackendForm.propTypes = FormPropTypes;
BackendForm.defaultProps = FormDefaultProps;
