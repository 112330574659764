import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { BackendForm, FloatingLabelField } from "../../forms";
import KioskButton from "./KioskButton";
import SearchInput from "../../search/SearchInput";
import { DataRow } from "../../layout";
import * as yup from "yup";

const CustomerModalPropTypes = {
    resolve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    error: PropTypes.string,
}

const CustomerModalDefaultProps = {
    error: '',
    allowNoCustomer: false,
}

export default function CustomerModal({ resolve, reject, close, error, allowNoCustomer }) {
    const [successfullyCreatedCustomer, setCustomer] = useState();

    return (
        <Modal show onHide={close} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add customer information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!allowNoCustomer && (
                    <Alert variant="warning">
                        <ExclamationTriangle className="me-2 mb-1" />
                        Buying on credit requires customer information.
                    </Alert>
                )}
                <Row className="d-flex gap-2">
                    <Col className="border-end pe-4 w-50">
                        <h4>Existing customer</h4>
                        <SearchInput
                            types={['customer']}
                            Component={({ item: customer }) => (
                                <KioskButton
                                    title={customer.title}
                                    cornerInfo={customer.context}
                                    onClick={() => {
                                        resolve(customer.object);
                                    }}
                                    className="w-100 py-1"
                                />
                            )}
                        />
                    </Col>
                    <Col className="flex-grow-1 w-50">
                        {error && <Alert variant="danger">{error}</Alert>}
                        {allowNoCustomer && (
                            <>
                                <KioskButton title="Finish without customer" onClick={() => resolve()} className="w-100 py-1" />
                                <hr />
                            </>
                        )}
                        <h4>Add new customer</h4>
                        {successfullyCreatedCustomer ? (
                            <>
                                <div className="my-4">
                                    <DataRow label="First name" labelSize={4}>{successfullyCreatedCustomer.first_name}</DataRow>
                                    <DataRow label="Last name" labelSize={4}>{successfullyCreatedCustomer.last_name}</DataRow>
                                    <DataRow label="Phone" labelSize={4}>{successfullyCreatedCustomer.phone}</DataRow>
                                </div>
                                <KioskButton
                                    title={`Finish with ${successfullyCreatedCustomer.first_name} ${successfullyCreatedCustomer.last_name}`}
                                    onClick={() => resolve(successfullyCreatedCustomer)}
                                    className="w-100 py-1"
                                />
                            </>
                        ) : (
                            <BackendForm
                                targetMethod="POST"
                                targetEndpoint="/customers"
                                initialValues={{ first_name: '', last_name: '', phone: '' }}
                                onSuccess={(resp) => {
                                    if (resp.data?.id) {
                                        setCustomer(resp.data);
                                        resolve(resp.data)
                                    } else {
                                        reject(resp.data);
                                    }
                                }}
                                validationSchema={yup.object({
                                    first_name: yup.string().required('Please enter a first name or initials'),
                                    last_name: yup.string().required('Please enter a last name or initials'),
                                    phone: yup.string(),
                                })}
                                showFooter={false}
                            >
                                <FloatingLabelField name="first_name" label="First name" />
                                <FloatingLabelField name="last_name" label="Last name" />
                                <FloatingLabelField name="phone" label="Phone" />
                                <BackendForm.Footer buttonText="Finish with new Customer" showError />
                            </BackendForm>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

CustomerModal.propTypes = CustomerModalPropTypes;
CustomerModal.defaultProps = CustomerModalDefaultProps;
