import React from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField} from "../../components/forms";
import {Pages} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";

const FieldsPropTypes = {}

const FieldsDefaultProps = {}

export default function Field() {
    const { fieldId } = useParams();
    const navigate = useNavigate();
    const isNew = fieldId === 'new';
    const { data: field, isLoading: isLoadingField, error: fieldError } = useGet({ endpoint: !isNew ? `/fields/${fieldId}`: '' });

    return (
        <Pages.Centred>
            {isLoadingField && <Loading />}
            {fieldError && <Alert variant="danger">{fieldError}</Alert>}
            {(isNew || field) && (
                <BackendForm
                    targetEndpoint={isNew ? '/fields' : `/fields/${fieldId}`}
                    targetMethod={isNew ? 'POST': 'PUT'}
                    initialValues={field || {
                        label: '',
                        description: '',
                    }}
                    enableReinitialize
                    onSuccess={(resp) => {
                        const id = resp?.data?.id;
                        navigate(id ? routes.field(id) : routes.fields)
                    }}
                >
                    <PageHeader
                        header={isNew ? 'Creating new field' : 'Editing Field'}
                        backUrl={routes.fields}
                    />
                    <FloatingLabelField name="label" label="Order label" />
                    <FloatingLabelField name="description" label="Order description" />
                </BackendForm>
            )}
        </Pages.Centred>
    );
}

Field.propTypes = FieldsPropTypes;
Field.defaultProps = FieldsDefaultProps;
