import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useAuth from "../hooks/useAuth";
import CurrencyAmount from "../layout/CurrencyAmount";

export default function ExchangeRates() {
    const { user } = useAuth();

    return (
        <div className="d-flex flex-column gap-sm-0 gap-md-3 flex-sm-row">
            <CurrencyAmount
                currency={user.organisation.currency}
                amount="1.00"
            />
            {user.organisation?.exchange_rates?.map((record) => (
                <React.Fragment key={record.id}>
                    <span className="d-none d-sm-inline-block">=</span>
                    <CurrencyAmount
                        key={record.id}
                        currency={record.currency}
                        amount={(1 * record.rate).toFixed(2)}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}
