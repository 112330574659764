import React, { useRef } from 'react';

const useOnHoldPropTypes = {}

const useOnHoldDefaultProps = {}

export default function useOnHold({ callback }) {
    const initMouseDown = useRef(Date.now());

    const onMouseDown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        initMouseDown.current = Date.now();
    };
    const onMouseUp = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (Date.now() - initMouseDown.current > 500) {
            callback();
        }
    };
    return { onMouseUp, onMouseDown, onTouchStart: onMouseDown, onTouchEnd: onMouseUp };
}

useOnHold.propTypes = useOnHoldPropTypes;
useOnHold.defaultProps = useOnHoldDefaultProps;
