import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DataRow} from "../layout";
import moment from "moment";
import {standardDateTimeFormat} from "../../utils/momentHelpers";
import CurrencyAmount from "../layout/CurrencyAmount";

const OrderInformationPropTypes = {}

const OrderInformationDefaultProps = {
    labelSize: 4
}

export default function OrderInformation({ order, labelSize }) {
    return (
        <>
            <DataRow label="Customer" labelSize={labelSize}>
                {order?.customer ? (
                    <>
                        {order?.customer?.first_name} {order?.customer?.last_name} <br/>
                        {order?.customer?.phone}
                    </>
                ) : <i>No customer details registered.</i>}
            </DataRow>
            <DataRow label="Checked out at" labelSize={labelSize}>
                {moment(order?.created_at).format(standardDateTimeFormat)}
            </DataRow>
            <DataRow label="Checked out by" labelSize={labelSize}>
                {order?.created_by?.name}
            </DataRow>
            <DataRow label="Discount rate" labelSize={labelSize}>
                {order?.discount_rate ? `${order.discount_rate * 100}%` : '-'}
            </DataRow>
        </>
    );
}

OrderInformation.propTypes = OrderInformationPropTypes;
OrderInformation.defaultProps = OrderInformationDefaultProps;
