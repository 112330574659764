import React, { useState, useMemo } from 'react';
import {Alert, Modal, Row, Col, FormGroup, Button} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import toast from "react-hot-toast";
import {ArrowDown, Building, Trash, Trash2, Trash2Fill, Truck} from "react-bootstrap-icons";
import useGet from "../components/hooks/useGet";
import Loading from "../components/Loading";
import { standardDateTimeFormat } from "../utils/momentHelpers";
import KioskButton from "../components/pos/kiosk/KioskButton";
import {Select} from "../components/forms";
import {inputClasses} from "../components/forms/inputClasses";
import {recordsToOptions} from "../components/forms/SelectField";
import useLocations from "../components/hooks/useLocations";
import {useDeleteModal} from "../utils/useDeleteModal";
import {backendEndpoints} from "../libs/backendEndpoints";
import {OptionBar} from "../components/layout";

const OPEN_VALUE = -1
export function useReceiveStockModal({  }) {
    const [location, setLocation] = useState(null);
    const modal = location && <ReceiveStockModal location={location} close={() => setLocation('')} />;
    return {
        openForLocation: (locationObject) => setLocation(locationObject),
        open: () => setLocation(OPEN_VALUE),
        modal,
    };
}

function Sequence({ sequence, replaceSequence }) {
    const [isProgressing, setProgressing] = useState(false);
    const { modal, deleteButtonCall } = useDeleteModal({
        endpoint: backendEndpoints.deleteSequence(sequence[0].sequence_id),
        onSuccess: () => replaceSequence(sequence[0].sequence_id, null),
    });

    const progress = (sequenceId) => {
        setProgressing(true);
        axios.put(`/stock-entries/sequences/${sequenceId}/progress`)
            .then((axiosResp) => {
                const { data, message } = axiosResp.data;
                toast.success(message);
                replaceSequence(sequenceId, data)
            })
            .catch((e) => {
                toast.error(e.response?.data?.message || e.message)
            })
            .finally(() => {
                setProgressing(false)
            })
    }

    const item = sequence[0].item;
    const change = sequence[0].change * -1;
    let renderedButtonFor = null;
    let prevLocationId = null;

    return (
        <>
            {modal}
            <div className="w-100 border-bottom pb-2 mb-3 bg-light p-2 shadow-s,">
                <div className="d-flex w-100 justify-content-between h4">
                    <strong>{item?.name}</strong>
                    <div>QTY: {change}</div>
                </div>
                <Row>
                    <Col sm={1}>
                        <Button variant="outline-danger" className="rounded-pill" onClick={deleteButtonCall}>
                            <Trash />
                        </Button>
                    </Col>
                    <Col className="flex-grow-1">
                        <div className="d-flex mt-2">
                            <strong>Note:&nbsp;</strong>
                            <p>{sequence[0]?.note || <i>No note</i>}</p>
                        </div>
                        {sequence.map((stockEntry, index) => {
                            const o = (
                                <>
                                    {index > 0 && <ArrowDown className="d-block mx-auto" />}
                                    <span key={stockEntry.id} className="d-flex gap-2 justify-content-between">
                                        <div className="w-33">
                                            {stockEntry.location.type === 1 ? <Building />  : <Truck />}
                                            <span>
                                                &nbsp;
                                                {stockEntry.location?.name}
                                            </span>
                                        </div>
                                        <span className="flex-grow-1">
                                            {stockEntry.confirmed_at
                                                ? <>{moment(stockEntry.confirmed_at).format(standardDateTimeFormat)} by {stockEntry.confirmed_by?.name}</>
                                                : <span className="text-success">{isProgressing ? <Loading /> : 'Not yet arrived'}</span>}
                                        </span>
                                    </span>
                                </>
                            )
                            if (!stockEntry.confirmed_at && !renderedButtonFor) {
                                renderedButtonFor = stockEntry
                            }
                            if (prevLocationId !== stockEntry.location_id) {
                                prevLocationId = stockEntry.location_id;
                                return o;
                            } else {
                                return null;
                            }
                        })}
                    </Col>
                    <Col sm={4}>
                        {renderedButtonFor && (
                            <KioskButton
                                title={`Received at ${renderedButtonFor.location?.name}`}
                                className="me-0 py-2"
                                onClick={() => progress(renderedButtonFor.sequence_id)}
                                disabled={isProgressing}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
}

const ReceiveStockModalPropTypes = {}

const ReceiveStockModalDefaultProps = {}

function ReceiveStockModal({ location: propLocation, close }) {
    const { locations } = useLocations();
    const [location, setLocation] = useState(propLocation);
    const [openOnly, setOpenOnly] = useState(1);
    const { data, error, isLoading } = useGet({
        endpoint: `/stock-entries/sequences/${location.id}?_with[]=item&_with[]=location&openOnly=${openOnly}`
    })
    const [replacements, setReplacements] = useState({})
    const replaceSequence = (id, sequence) => {
        setReplacements({...replacements, [id]: sequence });
    }

    const sequences = useMemo(() => {
        const arr = data ? Object.values({ ...data, ...replacements}) : [];
        return arr.reverse().slice(0, 2);
    }, [data, replacements]);
    return (
        <Modal show onHide={close} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Manage shipments for {location?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OptionBar stretch negativeMargin>
                    <OptionBar.Item transparent>
                        <Select
                            mandatory
                            value={location?.id}
                            className={`${inputClasses.kioskSelectInline} flex-grow-1`}
                            options={recordsToOptions(locations)}
                            onChange={e => setLocation(locations.find(l => l.id == e.target.value))}
                        />
                    </OptionBar.Item>
                    <OptionBar.Item transparent>
                        <Select
                            mandatory
                            value={openOnly}
                            className={`${inputClasses.kioskSelectInline} flex-grow-1`}
                            options={[{ value: 0, label: 'All shipments' }, { value: 1, label: 'Open only' }]}
                            onChange={e => setOpenOnly(parseInt(e.target.value))}
                        />
                    </OptionBar.Item>
                </OptionBar>
                {isLoading && <Loading />}
                {error && <Alert variant="danger">{error}</Alert>}
                {Array.isArray(sequences) && (
                    sequences.length > 0
                        ? sequences.map((sequence) => sequence ? <Sequence key={sequence[0].sequence_id} sequence={sequence} replaceSequence={replaceSequence} /> : null)
                        : <Alert className="text-lg">There are no shipments for {location?.name}.</Alert>
                )}
            </Modal.Body>
        </Modal>
    );
}

ReceiveStockModal.propTypes = ReceiveStockModalPropTypes;
ReceiveStockModal.defaultProps = ReceiveStockModalDefaultProps;
