import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    ArrowsMove,
    Globe,
    People,
    Percent,
    PersonBadge,
    ShareFill,
    TagsFill,
    Lock,
    BoxArrowRight,
} from "react-bootstrap-icons";
import IconWrapper from "./IconWrapper";

const StandardIcons = {
    OffLineOnline: () => <IconWrapper icon={Globe} />,
    Labels: () => <IconWrapper icon={TagsFill} />,
    Navigation: () => <IconWrapper icon={ArrowsMove} />,
    LibraryShare: () => <IconWrapper icon={ShareFill} />,
    Group: People,
    Logout: BoxArrowRight,
    Security: Lock,
    Account: PersonBadge,
    Subscriptions: Percent,
}

export default StandardIcons

