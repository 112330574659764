import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field, useField} from "formik";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import cx from "classnames";
import Description from "../Description";

const CheckboxesPropTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    })),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

const CheckboxesDefaultProps = {
}

export default function RadioBoxes({ options, name, label, disabled: allDisabled, inline }) {
    const [field,, { setValue }] = useField(name);

    useEffect(() => {
        if (typeof field.value === 'number') {
            setValue(`${field.value}`);
        }
    }, [field.value])

    return (
        <div className="mb-3">
            {label && (
                <div>
                    <FormCheckLabel>{label}</FormCheckLabel>
                </div>
            )}
            <div className={cx({
                'd-flex gap-4': inline,
            })}>
                {options.map((o) => {
                    const inputId = `checkbox-${name}-${o.value}`;
                    return (
                        <div key={o.value} className={`${inline ? 'd-inline-flex': 'd-flex'} align-items-start me-2`}>
                            <Field
                                type="radio"
                                name={name}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                className="mt-2"
                                value={o.value}
                                id={inputId}
                                checked={o.value === field.value}
                                disabled={o.disabled || allDisabled}
                            />
                            <label htmlFor={inputId} className={cx({ 'disabled': o.disabled })}>
                                <span className="fw-semibold">{o.label}</span>
                                {o.helperText && <Description className="text-sm">{o.helperText}</Description>}
                            </label>
                        </div>
                    )
                })}
            </div>
            <ErrorMessage name={name} className="text-danger" component="span" />
        </div>
    );
}

RadioBoxes.propTypes = CheckboxesPropTypes;
RadioBoxes.defaultProps = CheckboxesDefaultProps;
