import React from 'react';
import styles from './TileMenu.module.scss';
import {Link} from "react-router-dom";

const TileMenuPropTypes = {}

const TileMenuDefaultProps = {}

export default function TileMenu({ children }) {
    return (
        <div className={styles.TileMenu}>
            {children}
        </div>
    );
}

function Item({ children, header, icon: Icon, to }) {
    return (
        <Link to={to} className={styles.ItemContainer}>
            <div className={styles.IconContainer}>
                <Icon />
            </div>
            <h2>{header}</h2>
            {children}
        </Link>
    )
}

TileMenu.Item = Item

TileMenu.propTypes = TileMenuPropTypes;
TileMenu.defaultProps = TileMenuDefaultProps;
