import React from 'react';
import {useField} from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import {concepts} from "../../libs/lang";
import {Lock, Person} from "react-bootstrap-icons";
import useCheckExistingUser from "./useCheckExistingUser";

export default function SignUpFields() {
    return (
        <>
        </>
    );
}

const usernameFieldName = 'username';
const emailFieldName = 'email';

function Account({ showEmail }) {
    const [emailField] = useField(emailFieldName);
    const { alert } = useCheckExistingUser({ query: emailField.value, checkDuplicate: true });

    return (
        <>
            <Row>
                <Col>
                    <FloatingLabelField name="first_name" label="First name" required/>
                </Col>
                <Col>
                    <FloatingLabelField name="last_name" label="Last name" required/>
                </Col>
            </Row>
            {showEmail && <FloatingLabelField name={emailFieldName} label="E-mail" autoFocus required />}
            {alert}
            <FloatingLabelField name="organisation" label="Organisation" required/>
            <FloatingLabelField name="group_name" label={concepts.GroupName} />
            <FloatingLabelField name="position" label="Position"/>
        </>
    );
}
SignUpFields.Account = Account;

export const signUpInitialValues = {
    Account: {
        first_name: '',
        last_name: '',
        organisation: '',
        group_name: '',
        position: '',
    },
    UsernamePassword: {
        username: '',
        password: '',
        password_copy: '',
    },
}

const UserNamePassword = ({ checkDuplicate }) => {
    const [usernameField ] = useField(usernameFieldName);
    const { alert } = useCheckExistingUser({ query: usernameField.value, checkDuplicate });

    return (
        <>
            <FloatingLabelField
                name={usernameFieldName}
                label={concepts.Username}
                required
                icon={<Person />}
            />
            {alert}
            <FloatingLabelField name="password" label="Password" type="password" icon={<Lock/>} required/>
            <FloatingLabelField name="password_copy" label="Confirm password" type="password" icon={<Lock/>} required />
        </>
    )
}

SignUpFields.UsernamePassword = UserNamePassword;
