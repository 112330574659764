import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import cx from 'classnames';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { routes } from "../../utils";
import styles from './Layout.module.scss'
import useAuth from "../hooks/useAuth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useLocation} from "react-router-dom";
import LoggedInUser from "./LoggedInUser";
import {
    BarChart,
    Box,
    Collection,
    CurrencyEuro,
    List,
    Map, PeopleFill,
    Person,
    Receipt,
    ReceiptCutoff
} from "react-bootstrap-icons";
import {concepts} from "../../libs/lang";
import Logo from "../Logo";

const links = [
    { href: routes.dashboard, label: 'Dashboard', icon: <BarChart /> },
    { href: routes.locations, label: 'Locations', icon: <Map /> },
    { href: routes.fields, label: 'Fields', icon: <List /> },
    { href: routes.items, label: 'Items', icon: <Box /> },
    { href: routes.orders, label: 'Orders', icon: <CurrencyEuro /> },
    { href: routes.customers, label: 'Customers', icon: <Person />},
    { href: routes.itemGroups, label: 'Item Groups', icon: <Collection /> },
    { href: routes.users, label: 'Users', icon: <PeopleFill /> },
    { href: routes.kiosk, label: 'Kiosk', icon: <ReceiptCutoff /> },
]

const linkRender = ({ href, label, icon }) => (
    <Nav.Link
        as={Link}
        to={href}
        key={href}
        className={"d-flex flex-sm-row flex-md-column align-items-center justify-content-md-end py-sm-0 py-xs-3 px-xl-3 px-lg-1 px-md-1 "+styles.NavLink+' '+(window.location.pathname === href ? styles.active : '')}
        active={window.location.pathname === href}
    >
        <span className={`${styles.Icon} " h3 mx-2 my-0"`}>{icon}</span>
        <span className="small text-nowrap">
            {label}
        </span>
    </Nav.Link>
);

export default function Layout({ children }) {
    const { user } = useAuth()
    const [inverseOnFold, setInverseOnFold] = useState(false)
    const [userScrolledPastFold, setUserScrolledPastFold] = useState(false)
    const location = useLocation();

    useEffect(() => {
        if ([].indexOf(location.pathname) > -1) {
            if (!inverseOnFold) setInverseOnFold(true);
        } else {
            if (inverseOnFold) setInverseOnFold(false);
        }
    }, [location.pathname, inverseOnFold]);

    useEffect(() => {
        const onScroll = e => {
            const newValue = e.target.documentElement.scrollTop > 700;
            if (userScrolledPastFold != newValue) {
                setUserScrolledPastFold(newValue);
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [userScrolledPastFold])

    return (
        <>
            <Navbar
                expand="lg"
                className={cx({
                    [styles.Navbar]: true,
                    'shadow': true,
                    [styles.Inverse]: inverseOnFold && !userScrolledPastFold,
                    [styles.Regular]: !inverseOnFold || userScrolledPastFold,
                })}
                fixed="top"
            >
                <Container fluid>
                    <Logo />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {user && <Nav className="me-auto">{links.map(linkRender)}</Nav>}
                        <Nav>
                            {!user ? <Nav.Link as={Link} to={routes.login}>Log in</Nav.Link> : <LoggedInUser />}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <main>
                {children}
            </main>
            <footer>
                <Container className={`py-5 ${styles.Footer} mt-5`}>
                    <Row>
                        <Col className="gray-600 text-sm">
                            Copyright &copy; Scheuer Software {new Date().getFullYear()}.
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
