import {useState} from "react";
import {Alert, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Loading from "../components/Loading";
import {ExclamationTriangle} from "react-bootstrap-icons";
import toast from "react-hot-toast";

export function useDeleteModal({ endpoint, onSuccess }) {
    const [showModal, setShowModal] = useState();
    const [isDeleting, setDeleting] = useState();
    const [error, setError] = useState('');

    const deleteCall = () => {
        setDeleting(true);

        axios
            .delete(endpoint)
            .then((resp) => {
                onSuccess(resp)
                toast.success(resp.message || 'Successfully deleted.');
                return resp;
            })
            .catch((e) => setError(e.message))
            .finally(() => {
                setDeleting(false);
            });
    }

    return {
        deleteButtonCall: endpoint ? () => setShowModal(true) : undefined,
        modal: showModal && (
            <Modal
                show
                onHide={() => setShowModal(false)}
                size="sm"
                className="text-center modal-second-layer-z-index"
                backdropClassName="modal-second-layer-backdrop-z-index"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ExclamationTriangle className="text-danger" size={100} />
                    <p className="my-5">
                        Are you sure you want to delete this?&nbsp;
                        <b>This cannot be reversed.</b>
                    </p>
                    {error && <Alert>{error}</Alert>}
                    <Button onClick={deleteCall} disabled={isDeleting} size="lg">
                        Confirm deleting
                        {isDeleting && <Loading />}
                    </Button>
                </Modal.Body>
            </Modal>
        )
    };
}
