import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useKioskContext} from "./KioskContext";
import {Select} from "../../forms";
import {inputClasses} from "../../forms/inputClasses";
import {recordsToOptions} from "../../forms/SelectField";

export default function KioskLocation() {
    const { location, setLocation, locations } = useKioskContext();

    return (
        <Select
            mandatory
            value={location?.id}
            className={`${inputClasses.kioskSelectInline} flex-grow-1`}
            options={recordsToOptions(locations)}
            onChange={e => setLocation(locations.find(l => l.id == e.target.value))}
        />
    );
}
