import React, {useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {inputClasses} from "../components/forms/inputClasses";
import {Alert, Modal} from "react-bootstrap";
import KioskButton from "../components/pos/kiosk/KioskButton";
import SearchInput from "../components/search/SearchInput";
import OrderLineItemsList from "../components/orders/OrderLineItemsList";
import {BackendForm} from "../components/forms";
import useGet from "../components/hooks/useGet";
import toast from "react-hot-toast";
import {DataRow} from "../components/layout";
import OrderInformation from "../components/orders/OrderInformation";
import {computeSubtotal, computeTotal} from "../components/pos/kiosk/KioskHelpers";
import CurrencyAmount from "../components/layout/CurrencyAmount";
import moment from "moment";
import CreditForm from "../components/pos/CreditForm";

export default function useReturnItemModal({ inKiosk }) {
    const [isOpen, setOpen] = useState(false);

    return {
        open: () => setOpen(true),
        modal: isOpen ? (
            <ReturnItemModal inKiosk={inKiosk} close={() => setOpen(false)} />
        ) : null,
    };
}

function ReturnItemModal({ close }) {
    const [order, setOrder] = useState();
    const [dateNow, setDateNow] = useState(moment().unix());
    const subtotal = computeTotal({
        order_line_items: order?.order_line_items?.filter((o) => {
            return moment(o.created_at).unix() > dateNow;
        }) || [],
    });

    return (
        <>
            {subtotal != 0 && (
                <Modal show size="md" onHide={close}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Alert variant="success" className="text-xl text-left d-flex gap-3 mb-3">
                            <div>Please give back the following:</div>
                            <div className="text-center">
                                <CurrencyAmount amount={subtotal} showAll seperator={<b>Or </b>} />
                            </div>
                        </Alert>
                        <h3>How did you give it back?</h3>
                        <CreditForm
                            order={order}
                            onSuccess={() => setDateNow(moment().unix())}
                            positive={false}
                            maxTotal={subtotal * -1}
                        />
                    </Modal.Body>
                </Modal>
            )}
            <Modal show={subtotal == 0} size={order ? "xl" : 'md'} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Return item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex gap-3">
                        <div className="flex-grow-1 w-25">
                            <SearchInput
                                types={['order']}
                                Component={({ item: order, setSearchQuery }) => (
                                    <KioskButton
                                        title={order.title}
                                        cornerInfo={order.context}
                                        onClick={() => {
                                            setOrder(order.object);
                                            setSearchQuery('');
                                        }}
                                        className="w-100 py-1"
                                    />
                                )}
                            />
                        </div>
                        {order && (
                            <div className="flex-grow-1 w-75">
                                <BackendForm
                                    key={`${order.id}-${order.order_line_items.length}`}
                                    initialValues={order}
                                    targetEndpoint={`/orders/${order.id}?_with[]=order_line_items`}
                                    targetMethod="PUT"
                                    onSuccess={(resp) => {
                                        toast.success(resp.message);
                                        setOrder(resp.data);
                                    }}
                                    showFooter={false}
                                >
                                    <h3>Order #{order.id}</h3>
                                    <OrderInformation order={order} />
                                    <br />
                                    <OrderLineItemsList name="order_line_items" discountRate={order.discount_rate} />
                                    <BackendForm.Footer showError buttonText="Save changes" />
                                </BackendForm>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
