import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Alert} from "react-bootstrap";
import Loading from "../../components/Loading";

const UseCheckExistingUserPropTypes = {
    query: PropTypes.string,
    checkDuplicate: PropTypes.bool,
}

const UseCheckExistingUserDefaultProps = {
    checkDuplicate: false,
    query: '',
}

export default function useCheckExistingUser({ query, checkDuplicate }) {
    const [extendedError, setExtendedError] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isSending, setSending] = useState('');
    const timeoutRef = useRef();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (checkDuplicate && query) {
            setError('');
            timeoutRef.current = setTimeout(() => {
                const params = { query };
                axios.get('/users/check', { cancelToken: source.token, params }).then(({ data }) => {
                    const { status, resendVerificationUrl } = data.data;

                    const sendVerificationMail = () => {
                        setSending(true);
                        setExtendedError('');
                        axios.get(resendVerificationUrl, { cancelToken: source.token })
                            .then(({ data }) => {
                                setSuccess(data.message);
                            })
                            .catch((e) => {
                                setExtendedError(e.message);
                            })
                            .finally(() => setSending(false));
                    }

                    const common = (
                        <>
                            Click&nbsp;
                            <a className="fw-bold" onClick={sendVerificationMail}>here</a>
                            &nbsp;to resend the verification mail.
                        </>
                    );

                    switch (status) {
                        case 'EMAIL_EXISTS':
                            setError(<>This email already exists. {common}</>);
                            break;
                        case 'USERNAME_EXISTS':
                            setError(<>This username already exists. {common}</>);
                            break;
                    }
                })
            }, 300);
        }

        return () => {
            setSending(false)
            setSuccess('');
            clearTimeout(timeoutRef);
            source.cancel();
        }
    }, [query, checkDuplicate]);

    return {
        alert: (
            <>
                {error && !success && <Alert variant="danger">{isSending ? <Loading /> : <>{error} {extendedError}</>}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
            </>
        ),
    }
}

useCheckExistingUser.propTypes = UseCheckExistingUserPropTypes;
useCheckExistingUser.defaultProps = UseCheckExistingUserDefaultProps;
