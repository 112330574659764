import React, {useMemo, useState} from 'react';
import {Alert, Modal} from "react-bootstrap";
import moment from "moment";
import useGet from "../../hooks/useGet";
import KioskButton from "./KioskButton";
import {inputClasses} from "../../forms/inputClasses";
import Loading from "../../Loading";
import {OptionBar, Table} from "../../layout";
import {getTotalsPerCurrency, orderColumns} from "../../../utils/orders";
import {defaultDatabaseDateFormat, standardDateFormat, standardDateFormatWithDay} from "../../../utils/momentHelpers";
import {Select} from "../../forms";
import CurrencyAmount from "../../layout/CurrencyAmount";
import {useCurrencies} from "../../hooks/useCurrencies";


export default function DailySalesButton() {
    const { currencies } = useCurrencies();
    const [show, setShow] = useState();
    const [date, setDate] = useState(moment().format(defaultDatabaseDateFormat));

    const { data: orders, error, isLoading } = useGet({
        endpoint: `/orders?_with[]=creditBalance&created_at[]=${encodeURIComponent('>='+date+' 00:00:00')}&created_at[]=${encodeURIComponent('<='+date+' 23:59:59')}`,
    })

    const totalsPerCurrency = useMemo(() => {
        return getTotalsPerCurrency(orders, currencies);
    }, [orders])

    const close = () => {
        setShow(false);
    }

    const today = moment().format(standardDateFormat);
    const yesterdayObject = moment().subtract(1, 'day');
    const dayBeforeYesterdayObject = moment().subtract(2, 'day');

    return (
        <>
            <KioskButton
                className="bg-secondary"
                title="Daily sales"
                onClick={() => setShow(true)}
            />
            <Modal show={show} onHide={close} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Daily sales on {today}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OptionBar negativeMargin stretch>
                        <OptionBar.Item transparent>
                            <Select
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                className={`${inputClasses.kioskSelectInline} flex-grow-1`}
                                options={[
                                    { value: moment().format(defaultDatabaseDateFormat), label: 'Today' },
                                    { value: yesterdayObject.format(defaultDatabaseDateFormat), label: 'Yesterday - '+yesterdayObject.format(standardDateFormatWithDay) },
                                    { value: dayBeforeYesterdayObject.format(defaultDatabaseDateFormat), label: dayBeforeYesterdayObject.format(standardDateFormatWithDay) },
                                    ...[...new Array(10)].map((v, idx) => {
                                        const d = moment().subtract(3 + idx, 'day');
                                        return { value: d.format(defaultDatabaseDateFormat), label: d.format(standardDateFormatWithDay) };
                                    })
                                ]}
                            />
                        </OptionBar.Item>
                    </OptionBar>
                    <table className="table table-primary">
                        <tbody>
                            <tr>
                                <td>Net total {moment(date).format(standardDateFormat)}</td>
                                {Object.keys(totalsPerCurrency).map((curr, idx) => (
                                    <React.Fragment key={curr}>
                                        {idx > 0 && <td> and </td>}
                                        <td key={curr}>
                                            <CurrencyAmount amount={totalsPerCurrency[curr]} currency={curr} />
                                        </td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    {error && <Alert variant="error">{error}</Alert>}
                    {isLoading && <Loading/>}
                    <Table memoisedData={orders || []} memoisedColumns={orderColumns()} />
                </Modal.Body>
            </Modal>
        </>
    );
}

