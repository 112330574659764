import React from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {ArrowLeft} from "react-bootstrap-icons";

const PageHeaderPropTypes = {}

const PageHeaderDefaultProps = {}

export default function PageHeader({ header, backUrl, children, headerDescription }) {
    const navigate = useNavigate();
    return (
        <div className="d-flex align-items-center gap-4 border-bottom mb-4 pb-2">
            {backUrl && <Button onClick={() => navigate(backUrl)} variant="outline-primary"><ArrowLeft /> Go Back</Button>}
            <div className="flex-grow-1">
                <h1 className={headerDescription ? 'mb-0' : ''}>{header}</h1>
                {headerDescription && <small className="gray-600">{headerDescription}</small>}
            </div>
            {children && <span>{children}</span>}
        </div>
    );
}

PageHeader.propTypes = PageHeaderPropTypes;
PageHeader.defaultProps = PageHeaderDefaultProps;
