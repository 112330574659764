import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from "../ProfilePicture";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";
import {routes} from "../../utils";
import StandardIcons from "./StandardIcons";
import useAuth from "../hooks/useAuth";
import Button from "react-bootstrap/Button";

const LoggedInUserPropTypes = {}

const LoggedInUserDefaultProps = {}

export default function LoggedInUser() {
    const { user } = useAuth();

    return (
        <NavDropdown
            title={(
                <>
                    <div className="d-inline-flex align-items-center">
                        {user && <ProfilePicture user={user} />}
                        <span className="d-none d-sm-inline">
                            <div className="text-light">{user?.name}</div>
                        </span>
                    </div>
                </>
            )}
            className="LoginDropdown"
        >
            {user?.is_admin ? (
                <>
                    <NavDropdown.Item as={Link} to={routes.orders}>To Admin</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={routes.organisation}>My Organisation</NavDropdown.Item>
                </>
            ) : null}
            <NavDropdown.Item as={Link} to={routes.userSettings}>My Account</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={routes.logout} className="text-danger">
                <StandardIcons.Logout />
                &nbsp;
                Log out
            </NavDropdown.Item>
        </NavDropdown>
    );
}

LoggedInUser.propTypes = LoggedInUserPropTypes;
LoggedInUser.defaultProps = LoggedInUserDefaultProps;
