import React, {useEffect, useId} from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field, useField} from "formik";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import cx from "classnames";

const CheckboxesPropTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
}

const CheckboxesDefaultProps = {
}

export default function CheckBox({ checked, name, label, disabled, inline, onChange }) {
    const id = useId();

    return (
        <>
            {/*<div>*/}
            {/*    <FormCheckLabel>{label}</FormCheckLabel>*/}
            {/*</div>*/}
            <div className={cx({
                'd-flex gap-4': inline,
            })}>
                <input
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    checked={checked}
                    value="1"
                    id={id}
                    className="me-2"
                    style={{ width: 20, height: 20 }}
                    disabled={disabled}
                />
                <label htmlFor={id} className={cx({ 'disabled': disabled })}>{label}</label>
            </div>
        </>
    );
}

CheckBox.propTypes = CheckboxesPropTypes;
CheckBox.defaultProps = CheckboxesDefaultProps;
