import React, { useState } from 'react';
import { PencilFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {Alert, ButtonGroup, InputGroup, Modal} from "react-bootstrap";
import {inputClasses} from "../../forms/inputClasses";
import CurrencyAmount from "../../layout/CurrencyAmount";
import KioskButton from "./KioskButton";

const presetRates = [0, .025, .05, 0.10, 0.15, 0.20];

export default function DiscountModal({ setDiscountRate, discountRate, baseTotal }) {
    const [localRate, setLocalRate] = useState(Number.isNaN(parseFloat(discountRate)) ? 0 : discountRate * 100);
    const [show, setShow] = useState(false);
    const [isCustom, setCustom] = useState(false);

    const isANumber = !Number.isNaN(parseFloat(localRate));

    return (
        <>
            <Button onClick={() => setShow(true)} variant="secondary">
                <PencilFill />
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header onClose>
                    <Modal.Title>Adjust the discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-3">
                        <ButtonGroup>
                            {presetRates.map((rate) => (
                                <Button
                                    key={rate}
                                    onClick={() => {
                                        setLocalRate(rate * 100)
                                        setCustom(false);
                                    }}
                                    className="p-3"
                                    variant={rate === localRate / 100 ? 'primary' : 'outline-primary'}
                                >
                                    {(rate * 100).toFixed(Math.round(rate * 100) === rate * 100 ? 0 : 1)}%
                                </Button>
                            ))}
                        </ButtonGroup>
                        <Button
                            className="p-3"
                            onClick={() => setCustom(true)}
                            variant={isCustom ? 'primary' : 'outline-primary'}
                        >
                            XX %
                        </Button>
                    </div>
                    {isCustom && (
                        <InputGroup className="d-inline-flex justify-content-center">
                            <input
                                className={inputClasses.numberInput}
                                placeholder="Enter..."
                                value={localRate}
                                onChange={(e) => setLocalRate(e.target.value)}
                                style={{ maxWidth: 150, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    )}
                    <hr />
                    {!isANumber ? (
                        <Alert>Please enter a valid number</Alert>
                    ) : (
                        <div className="text-xl text-center">
                            <label>Discount:&nbsp;</label>
                            <strong className="text-primary">
                                <CurrencyAmount amount={baseTotal * localRate / 100} showAll />
                            </strong>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {isANumber && (
                        <KioskButton
                            title="Confirm"
                            onClick={() => {
                                setDiscountRate(parseFloat(localRate) / 100);
                                setShow(false);
                            }}
                        />
                    )}

                </Modal.Footer>
            </Modal>
        </>
    );
}
