import React from 'react';
import { Alert } from "react-bootstrap";
import {convertAmount} from "../../layout/CurrencyAmount";

export const ACCEPTABLE_DIFF = 0.05;
export const ACCEPTABLE_MESSAGE = <span>{`You are allowed to deviate ${ACCEPTABLE_DIFF * 100}%.`}</span>;

export function TooLowAmount() {
    return <Alert variant="danger">The amount should be lower than the total.</Alert>;
}

export function evaluateAmounts({ amounts, currencies, user, isValidNumber, totals }) {
    const validAmounts = [];
    let totalValid = 0;
    amounts.forEach((amount, idx) => {
        const curr = currencies[idx];
        if (isValidNumber(amount, curr)) {
            validAmounts.push(amount);
            totalValid += convertAmount(amount, user, curr, true);
        }
    });
    const diff = totals.total > totalValid ? totals.total - totalValid : 0;
    const acceptableDiff = totals.total * ACCEPTABLE_DIFF;
    return {
        validAmounts,
        diff,
        acceptableDiff,
        totalValid,
        tooMuch: totalValid >= totals.total + acceptableDiff,
        tooLittle: totalValid < totals.total - acceptableDiff,
    }
}

export function computeSubtotal({ order_line_items }) {
    return order_line_items.reduce((acc, record) => acc + record.subtotal_amount, 0).toFixed(2)
}

export function computeTotal({ order_line_items }) {
    return order_line_items.reduce((acc, record) => acc + record.total_amount, 0).toFixed(2)
}
