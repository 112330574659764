import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import {Pages, Table} from "../../components/layout";
import {routes} from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import {Alert} from "react-bootstrap";
import {orderColumns} from "../../utils/orders";
import Loading from "../../components/Loading";
import {routeLabels} from "../../utils/routes";


export default function Orders() {
    const { data, error, isLoading } = useGet({ endpoint: '/orders?_orderBy=created_at&_orderDirection=DESC' });

    const memoData = useMemo(() => Array.isArray(data) ? data : [], [data]);
    const memoColumns = useMemo(() => orderColumns(), []);

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.orders, label: routeLabels.orders },
            ]}
            padded={false}
        >
            <PageHeader header="Orders" backUrl={routes.dashboard} />
            {error && <Alert>{error}</Alert>}
            {isLoading
                ? <Loading />
                : <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={order => routes.order(order.id)} />}
        </Pages.Centred>
    );
}

