import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import { Table, Pages } from "../../components/layout";
import {routes} from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import {Alert} from "react-bootstrap";
import Loading from "../../components/Loading";
import {routeLabels} from "../../utils/routes";


export default function Customers() {
    const { data, isLoading, error } = useGet({ endpoint: '/customers' });

    const memoData = useMemo(() => Array.isArray(data) ? data : [], [data]);
    const memoColumns = useMemo(() => [
        { accessor: 'first_name', header: 'First name' },
        { accessor: 'last_name', header: 'Last name' },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.customers, label: routeLabels.customers },
            ]}
            padded={false}
        >
            <PageHeader header={routeLabels.customers} backUrl={routes.dashboard} />
            {error && <Alert>{error}</Alert>}
            {isLoading
                ? <Loading />
                : <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={field => routes.customer(field.id)} />
            }
        </Pages.Centred>
    );
}

