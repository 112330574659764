import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import { Table, Pages } from "../../components/layout";
import Button from "react-bootstrap/Button";
import { PlusLg } from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import { routes } from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import {availableInKioskOptions} from "../../components/locations/locationUtils";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import {routeLabels} from "../../utils/routes";


export default function Locations() {
    const { data, isLoading, error } = useGet({ endpoint: '/locations' });
    const navigate = useNavigate();

    const memoData = useMemo(() => Array.isArray(data) ? data : [], [data]);
    const memoColumns = useMemo(() => [
        { accessor: 'type_name', header: 'Type' },
        { accessor: 'name', header: 'Name' },
        { accessor: 'description', header: 'Description' },
        {
            accessor: 'available_in_kiosk',
            header: 'Available in kiosk',
            Cell: ({ value }) => Object.values(availableInKioskOptions).find((o) => value == o.value)?.label,
        },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            header="Items"
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.locations, label: routeLabels.locations },
            ]}
            padded={false}
        >
            <PageHeader header="Locations" headerDescription="Manage your store locations and transport lines." backUrl={routes.dashboard}>
                <Button variant="outline-primary" onClick={() => navigate(routes.location('new'))}>
                    <PlusLg /> New location
                </Button>
            </PageHeader>
            {error && <Alert>{error}</Alert>}
            {isLoading ? <Loading /> : <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={field => routes.location(field.id)} />}
        </Pages.Centred>
    );
}

