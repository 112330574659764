import React, { useState, useContext } from 'react';
import styles from './MobileSlideMenu.module.scss';
import Button from "react-bootstrap/Button";
import { ButtonGroup } from "react-bootstrap";

const Context = React.createContext({ activeIndex: 0 });

export default function MobileSlideMenu({ children, titles }) {
    const [activeIndex, setIndex] = useState(0);

    return (
        <Context.Provider value={{ activeIndex }}>
            <div className="d-sm-none d-flex vw-100" style={{ height: 50 }}>
                <ButtonGroup className="fixed-top gap-1" style={{ top: 92 }}>
                    {titles.map((title, idx) => (
                        <Button key={idx} onClick={() => setIndex(idx)} className={`w-33 py-2 rounded-0`} variant={idx === activeIndex ? 'primary': 'primary-dark'}>
                            {title}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
            <div className="d-flex gap-2 vw-100">
                {children}
            </div>
        </Context.Provider>
    );
}

function Tab({ children, className, index }) {
    const { activeIndex } = useContext(Context);

    return (
        <div className={`${className} overflow-scroll ${styles.Tab} ${activeIndex === index ? 'd-block' : 'd-none d-sm-block'}`}>
            {children}
        </div>
    );
}
Tab.defaultProps = {
    className: '',
}
MobileSlideMenu.Tab = Tab;
