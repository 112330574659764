import React from 'react';
import CurrencyAmount from "../../layout/CurrencyAmount";
import Description from "../../Description";

const SumAmountPropTypes = {}

const SumAmountDefaultProps = {}

export default function SumAmount({ totalValid, baseCurrency }) {
    return (
        <Description className="text-xl">
            Summed up total value: &nbsp;
            <b className="text-primary">
                <CurrencyAmount.Converted baseAmount={totalValid} currency={baseCurrency} />
            </b>.
        </Description>
    );
}

SumAmount.propTypes = SumAmountPropTypes;
SumAmount.defaultProps = SumAmountDefaultProps;
