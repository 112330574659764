import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import axios from "axios";
import Loading from "../Loading";
import {Alert} from "react-bootstrap";
import {useField} from "formik";

const ImagePropTypes = {}

const ImageDefaultProps = {}

export default function ImageUploader({ label, type, typeId, name, onSuccess, className }) {
    const [isUploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [{ value: image }, , { setValue: setImage }] = useField(name);

    const uploadFile = (e) => {
        const file = e.target.files[0];
        const fd = new FormData();
        fd.append('file', file);
        fd.append('imageable_type', type);
        fd.append('imageable_id', typeId);
        setUploading(true);
        setError('');
        axios.post('/images', fd)
            .then((axiosResp) => {
                setImage(axiosResp.data.data);
                if (onSuccess) onSuccess();
            })
            .catch((e) => {
                setError(e.data?.message || e.message)
            })
            .finally(() => {
                setUploading(false);
            })
    };

    return (
        <div>
            <div>
                <FormCheckLabel>{label}</FormCheckLabel>
            </div>
            <input type="file" onChange={uploadFile} disabled={isUploading} />
            {error && <Alert variant="error">{error}</Alert>}
            {isUploading && <Loading />}
            {image?.filename && (
                <div
                    className={`${className} mt-2`}
                    style={{ width: 200, height: 200, backgroundImage: `url(${image?.url})`, backgroundPosition: 'center center' }}
                />
            )}
        </div>
    );
}

ImageUploader.propTypes = ImagePropTypes;
ImageUploader.defaultProps = ImageDefaultProps;
