import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import { Table, Pages } from "../../components/layout";
import {routes} from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import {PlusLg} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {roleOptions} from "../../components/users/usersUtils";
import {Alert} from "react-bootstrap";
import Loading from "../../components/Loading";
import {routeLabels} from "../../utils/routes";

export default function Users() {
    const navigate = useNavigate();
    const { data, error, isLoading } = useGet({ endpoint: '/users' });

    const memoData = useMemo(() => Array.isArray(data) ? data : [], [data]);
    const memoColumns = useMemo(() => [
        { accessor: 'name', header: 'Name' },
        { accessor: 'role', header: 'Role', Cell: ({ value }) => Object.values(roleOptions).find((o) => value == o.value)?.label },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.users, label: routeLabels.users },
            ]}
            padded={false}
        >
            <PageHeader header="Users" backUrl={routes.dashboard}>
                <Button variant="outline-primary" onClick={() => navigate(routes.user('new'))}>
                    <PlusLg /> New user
                </Button>
            </PageHeader>
            {error && <Alert>{error}</Alert>}
            {isLoading
                ? <Loading />
                : <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={field => routes.user(field.id)} />
            }
        </Pages.Centred>
    );
}
