import React, {useMemo} from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField, SelectField} from "../../components/forms";
import {Pages} from "../../components/layout";
import {useNavigate} from "react-router";
import {routes} from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import toast from "react-hot-toast";
import {hashMap, indexMap} from "../../utils/hashMap";
import PriceBuilder from "../../components/prices/PriceBuilder";
import StockMutations from "../../components/stock/StockMutations";
import {Alert} from "react-bootstrap";
import { useItem } from "../../components/hooks/useItem";
import {recordsToOptions} from "../../components/forms/SelectField";
import ImageUploader from "../../components/forms/ImageUploader";
import {routeLabels} from "../../utils/routes";

const FieldsPropTypes = {}

const FieldsDefaultProps = {}

const emptyItemObject = {
    name: '',
    item_group_id: '',
    prices: [],
    fields: [],
};
export default function Item() {
    const { data: fields, isLoading: isLoadingFields, error: fieldsError } = useGet({ endpoint: '/fields' });
    const { data: itemGroups } = useGet({ endpoint: '/item-groups' });

    const { itemId } = useParams();
    const isNew = itemId === 'new';
    const { item, isLoadingItem, itemsError } = useItem(itemId);
    const error = itemsError || fieldsError;
    const isLoading = isLoadingItem || isLoadingFields;
    const navigate = useNavigate();

    const fieldIndexes = useMemo(() => fields ? indexMap(fields) : {}, [fields])

    const initialValues = useMemo(() => {
        if (!item) return emptyItemObject;

        const o = { ...item, fields: [] };

        const itemFieldIndicies = hashMap(item.fields, 'id');
        if (Array.isArray(fields)) {
            fields.forEach((field) => {
                o.fields.push(
                    { id: field.id, pivot: itemFieldIndicies[field.id]?.pivot || { value: '' } }
                );
            })
        }
        return o;
    }, [fieldIndexes, fields, item])

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.items, label: routeLabels.items },
                { href: '', label: isNew ? 'Creating new': item?.id },
            ]}
            padded={false}
        >
            {error && <Alert variant="danger">{error}</Alert>}
            {isLoading ? <Loading /> : (
                <BackendForm
                    targetEndpoint={isNew ? '/items?_with[]=fields&_with[]=prices' : `/items/${itemId}?_with[]=fields&_with[]=prices`}
                    targetMethod={isNew ? 'POST': 'PUT'}
                    initialValues={initialValues}
                    enableReinitialize
                    onSuccess={(resp) => {
                        const id = resp?.data?.id;
                        navigate(id ? routes.item(id) : routes.items)
                        toast.success(resp.message);
                    }}
                >
                    <PageHeader
                        header={isNew ? 'Creating new item' : `Editing item: ${item?.name}`}
                        backUrl={routes.items}
                    />
                    <div className="d-flex align-content-between gap-3">
                        <div className="flex-grow-1 w-33">
                            <h3>Information</h3>
                            <FloatingLabelField name="name" label="Item name" />
                            <SelectField name="item_group_id" label="Item group name" options={recordsToOptions(itemGroups)} placeholder="--- Optional ---"/>
                            <br />
                            {Array.isArray(fields) && fields.map((field, idx) => (
                                <FloatingLabelField
                                    key={field.id}
                                    name={`fields.${idx}.pivot.value`}
                                    label={field.label}
                                    helperText={field.description}
                                />
                            ))}
                            {itemId && <ImageUploader name="image" label="Kiosk file" type="App\Models\Item" typeId={itemId}  />}
                        </div>
                        <div className="flex-grow-1 w-33">
                            <h2>Prices</h2>
                            <PriceBuilder name="prices" />
                        </div>
                        {item?.id && (
                            <div className="flex-grow-1 w-33">
                                <h2>Stock</h2>
                                <StockMutations itemId={item.id} />
                            </div>
                        )}
                    </div>
                </BackendForm>
            )}
        </Pages.Centred>
    );
}

Item.propTypes = FieldsPropTypes;
Item.defaultProps = FieldsDefaultProps;
