import React, { useState } from 'react';
import useGet from "../../hooks/useGet";
import KioskButton from "./KioskButton";
import {Alert, Modal} from "react-bootstrap";
import {inputClasses} from "../../forms/inputClasses";
import Description from "../../Description";
import Loading from "../../Loading";
import SearchResultButton from "../../search/SearchResultButton";
import {DataRow} from "../../layout";
import CurrencyAmount from "../../layout/CurrencyAmount";
import Button from "react-bootstrap/Button";
import OrderInformation from "../../orders/OrderInformation";
import CreditForm from "../CreditForm";
import MiniOrderItemsTable from "./MiniOrderItemsTable";
import MiniPaymentsTable from "./MiniPaymentsTable";


export default function PayOffDebtButton() {
    const [value, setValue] = useState('');
    const [show, setShow] = useState();
    const [order, setOrder] = useState();
    const {
        data: recommendations,
        error: recommendationError,
        isLoading: isLoadingRecommendations,
    } = useGet({ endpoint: value || !show ? '' : '/orders/debt' });
    const { data, error, isLoading } = useGet({ endpoint: value ? `/search?type[]=order&q=${value}` : ''})

    const onClick = (order) => {
        setValue('');
        setOrder(order)
    };
    const renderOrder = (searchOrderResult) => <SearchResultButton.Order order={searchOrderResult} onClick={() => onClick(searchOrderResult.object)} />;
    const close = () => {
        setShow(false);
        setOrder();
        setValue('');
    }

    return (
        <>
            <KioskButton
                className="bg-secondary"
                title="Pay off debt"
                onClick={() => setShow(true)}
            />
            <Modal show={show} onHide={close} size={order ? 'xl' : 'md'}>
                <Modal.Header closeButton>
                    <Modal.Title>Pay off debt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex gap-3">
                        <div className="flex-grow-1 w-33">
                            <input
                                placeholder="Search by name, phone number or order id..."
                                autoFocus
                                className={inputClasses.queryBox}
                                value={value}
                                onChange={e => {
                                    setOrder();
                                    setValue(e.target.value);
                                }}
                            />
                            {error && <Alert variant="danger">{error}</Alert>}
                            {recommendationError && <Alert variant="danger">{recommendationError}</Alert>}
                            {isLoading && <Loading />}
                            {order
                                ? renderOrder(order)
                                : (
                                    <>
                                        {value ? (
                                            <>
                                                {Array.isArray(data) && (data.length > 0 ? (
                                                    data.map(renderOrder)
                                                ) : <Description>No order found</Description>)}
                                            </>
                                        ) : (
                                            <>
                                                <hr />
                                                <h3>Orders with debt</h3>
                                                {isLoadingRecommendations ? <Loading /> : (
                                                    Array.isArray(recommendations) && recommendations.map((order) => (
                                                        <KioskButton.Order
                                                            order={order}
                                                            key={order.id}
                                                            onClick={() => onClick(order)}
                                                        />
                                                    ))
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                        </div>
                        {order && (
                            <>
                                <div className="border-left ps-3 flex-grow-1 w-33">
                                    <Button variant="outline-primary" className="rounded-pill mb-3" size="sm" onClick={() => setOrder()}>&larr; Change</Button>
                                    <OrderInformation labelSize={5} order={order} />
                                    <DataRow labelSize={5} label="Total order value">
                                        <CurrencyAmount amount={order.total_amount} showAll />
                                    </DataRow>
                                    <DataRow labelSize={5} label="Open debt">
                                        <CurrencyAmount amount={order.open_debt} showAll />
                                    </DataRow>
                                    <br />
                                    <MiniOrderItemsTable items={order.order_line_items} />

                                    <MiniPaymentsTable items={order.credit_balance} />
                                </div>
                                <div className="border-left ps-3 flex-grow-1 w-33">
                                    <h4 className="mt-5">How much is paid off?</h4>
                                    <Description>The amount entered will be deducted from the open debt.</Description>
                                    <CreditForm order={order} maxTotal={order.open_debt} positive onSuccess={close} />
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

