import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import toast from "react-hot-toast";
import {convertAmount} from "../layout/CurrencyAmount";
import {BackendForm} from "../forms";
import {useFormikContext} from "formik";
import {useKioskContext} from "./kiosk/KioskContext";
import {ACCEPTABLE_MESSAGE, evaluateAmounts} from "./kiosk/KioskHelpers";
import PaidSection from "./kiosk/PaidSection";
import SumAmount from "./kiosk/SumAmount";
import {Alert} from "react-bootstrap";
import useAuth from "../hooks/useAuth";

const CreditFormPropTypes = {
    positive: PropTypes.bool.isRequired
};

const CreditFormDefaultProps = {};

export default function CreditForm({ order, onSuccess, positive, maxTotal }) {
    const { user } = useAuth();
    const baseCurrency = user.organisation.currency;
    const currencies = [baseCurrency].concat(user?.organisation?.exchange_rates?.map(r => r.currency))

    return (
        <BackendForm
            targetMethod="POST"
            targetEndpoint="/order-credit-balances"
            initialValues={{
                order_id: order.id,
                checked_out_currency: currencies, checked_out_amount: currencies.map(() => '0')
            }}
            showFooter={false}
            onSuccess={(resp) => {
                if (Array.isArray(resp.data) ? resp.data.length > 0 : resp.data.id) {
                    toast.success(resp.message);
                    onSuccess();
                }
            }}
            modifyValuesPreSubmit={(values) => {
                values.amount = values.checked_out_amount.map(
                    (a, i) => convertAmount(a * (positive ? 1 : -1), user, values.checked_out_currency[i], true).toFixed(2)
                )
                return values;
            }}
        >
            <InnerWrapper baseCurrency={baseCurrency} user={user} maxTotal={maxTotal} />
        </BackendForm>
    );
}

CreditForm.propTypes = CreditFormPropTypes;
CreditForm.defaultProps = CreditFormDefaultProps;


function InnerWrapper({ baseCurrency, user, maxTotal }) {
    const { values } = useFormikContext();
    const { isValidNumber } = useKioskContext();
    const { totalValid, tooMuch } = evaluateAmounts({
        amounts: values.checked_out_amount,
        currencies: values.checked_out_currency,
        user,
        isValidNumber,
        totals: {
            total: maxTotal,
        },
    });

    return (
        <>
            <PaidSection currencyName="checked_out_currency" name="checked_out_amount" />
            <SumAmount baseCurrency={baseCurrency} totalValid={totalValid} />
            {tooMuch ? <Alert>Too much. {ACCEPTABLE_MESSAGE}</Alert> : <BackendForm.Footer showError />}
        </>
    );
}
