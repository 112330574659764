import React, {useState, useMemo} from 'react';
import _ from 'lodash';
import useGet from "../hooks/useGet";
import {standardDateTimeFormat} from "../../utils/momentHelpers";
import moment from "moment";
import {DataRow, Table} from "../layout";
import useLocations from "../hooks/useLocations";
import { Select } from "../forms";
import {recordsToOptions} from "../forms/SelectField";
import {CurrencyEuro, PencilFill, Truck} from "react-bootstrap-icons";
import {computeStock, computeTotalStock} from "./computeStock";
import {inputClasses} from "../forms/inputClasses";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {hashMap} from "../../utils/hashMap";

const StockMutationsPropTypes = {}

const StockMutationsDefaultProps = {}

export default function StockMutations({ itemId }) {
    const { locations } = useLocations();
    const [selectedLocationId, setSelectedLocationId] = useState('');

    const {data: stockEntries} = useGet({endpoint: `/stock-entries?item_id=${itemId}`});
    const {data: orderLineItems} = useGet({endpoint: `/order-line-items?item_id=${itemId}&_with[]=price`});

    const mutationsPerLocation = useMemo(() => {
        const list = computeStock({ stockEntries, orderLineItems, locations });

        for (const listKey in list) {
            list[listKey] = _.orderBy(list[listKey], ['date'], ['desc'])
        }
        return list;
    }, [stockEntries, orderLineItems, selectedLocationId, locations]);

    const sumAtLocation = computeTotalStock(mutationsPerLocation[selectedLocationId]);
    const selectedLocationName = locations?.find(l => l.id === selectedLocationId)?.name;

    return (
        <>
            <Select
                options={recordsToOptions(locations)}
                value={selectedLocationId}
                mandatory
                className={`${inputClasses.kioskSelectFullWidth} mb-3`}
                onChange={(e) => setSelectedLocationId(parseInt(e.target.value, 10))}
            />
            <DataRow labelSize={9} label={`Currently on stock in ${selectedLocationName}:`}>{sumAtLocation.current}</DataRow>
            <DataRow labelSize={9} label={`Destined for ${selectedLocationName}:`}>{sumAtLocation.upcoming}</DataRow>
            <br />
            <Table
                memoisedData={mutationsPerLocation[selectedLocationId] || []}
                memoisedColumns={[
                    {
                        header: '',
                        accessor: 'type',
                        Cell: ({ value, row }) => {
                            switch (value) {
                                case 'SHIPMENT': return <OverlayTrigger overlay={<Tooltip id="shipment">Via shipment</Tooltip>}><Truck /></OverlayTrigger>;
                                case 'SALE': return <OverlayTrigger overlay={<Tooltip id="order">Via an order</Tooltip>}><CurrencyEuro /></OverlayTrigger>;
                                case 'EDIT': return <OverlayTrigger overlay={<Tooltip id="order">Manual edit</Tooltip>}><PencilFill /></OverlayTrigger>;
                                default: return null;
                            }
                        }
                    },
                    {
                        header: 'Date',
                        accessor: 'date',
                        Cell: ({value}) => moment(value).format(standardDateTimeFormat)
                    }, {
                        header: '',
                        accessor: 'note',
                        Cell: ({ value, row }) => {
                            if (value) return value;
                            console.log(row.original);
                            if (row.original?.type === 'EDIT') return row.original.entry?.created_by?.name;
                        }
                    }, {
                        header: 'Change',
                        accessor: 'change',
                    }
                ]}
            />

        </>
    );
}

StockMutations.propTypes = StockMutationsPropTypes;
StockMutations.defaultProps = StockMutationsDefaultProps;
