import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useField } from "formik";
import Button from "react-bootstrap/Button";
import {Plus, Trash3} from "react-bootstrap-icons";
import Alert from "react-bootstrap/Alert";
import cx from "classnames";

function DeleteButton({ onDelete, onSuccess }) {
    const [isDeleting, setDeleting] = useState(false);
    const [error, setError] = useState('');

    return (
        <>
            <Button
                variant="outline-danger"
                onClick={() => {
                    setError('')
                    if (!isDeleting) {
                        if (typeof onDelete === 'function') {
                            setDeleting(true)
                            onDelete()
                                .then(() => {
                                    onSuccess()
                                })
                                .catch((e) => {
                                    setError(e.response?.data?.message)
                                })
                                .finally(() => setDeleting(false))
                        } else {
                            onSuccess()
                        }
                    }
                }}
            >
                <Trash3 />
            </Button>
            {error && <Alert className="alert-danger">{error}</Alert>}
        </>
    )
}
DeleteButton.propTypes = {
    onDelete: PropTypes.func,
}

const DynamicListPropTypes = {
    emptyEntry: PropTypes.oneOfType([PropTypes.shape]),
    name: PropTypes.string.isRequired,
}

const DynamicListDefaultProps = {
    addButton: false,
    emptyEntry: {},
    enableDelete: true,
}

export default function DynamicList({ name, emptyEntry, Component, addButton, onDelete, maxSize, minSize, enableDelete }) {
    const [{ value },, { setValue }] = useField(name);

    useEffect(() => {
        if (minSize) {
            const diff = minSize - (Array.isArray(value) ? value.length : 0);
            if (diff > 0) {
                setValue((Array.isArray(value) ? value : []).concat([...new Array(diff)].fill(emptyEntry, diff)))
            }
        }
    }, [minSize]);

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => {
                return (
                    <div>
                        {Array.isArray(value) && value.length > 0 && (
                            value.map((item, index) => (
                                <div
                                    key={index}
                                    className={cx({
                                        "d-sm-flex flex-row px-3 py-2 align-items-center mb-1": true,
                                        "bg-light": true,
                                        "rounded-bottom": index === value.length - 1,
                                        "rounded-top": index === 0,
                                    })}
                                >
                                    <div className="flex-sm-fill me-4">
                                        <Component
                                            item={item}
                                            name={`${name}.${index}`}
                                            index={index}
                                            prevItem={index > 0 ? value[index - 1] : null}
                                            baseName={name}
                                        />
                                    </div>
                                    {enableDelete && value.length >= 2 && (!minSize || value.length > minSize) && (
                                        <div>
                                            <DeleteButton
                                                onSuccess={() => {
                                                    arrayHelpers.remove(index)
                                                }}
                                                onDelete={onDelete ? () => onDelete(item) : undefined}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                        {addButton && (!maxSize || (Array.isArray(value) && value.length < maxSize)) && (
                            <Button onClick={() => arrayHelpers.push(emptyEntry)} className="ms-auto d-block mb-1">
                                <Plus /> {addButton}
                            </Button>
                        )}
                    </div>
                )
            }}
        />
    );
}

DynamicList.propTypes = DynamicListPropTypes;
DynamicList.defaultProps = DynamicListDefaultProps;
