import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import { Table, Pages } from "../../components/layout";
import Button from "react-bootstrap/Button";
import {PlusLg} from "react-bootstrap-icons";
import {useNavigate} from "react-router";
import {routes} from "../../utils";
import PageHeader from "../../components/layout/PageHeader";
import {routeLabels} from "../../utils/routes";

export default function Fields() {
    const { data } = useGet({ endpoint: '/fields' });
    const navigate = useNavigate();

    const memoData = useMemo(() => Array.isArray(data) ? data : [], [data]);
    const memoColumns = useMemo(() => [
        { accessor: 'label', header: 'Field label' },
        { accessor: 'description', header: 'Description' },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            header="Items"
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.fields, label: routeLabels.fields },
            ]}
            padded={false}
        >
            <PageHeader header="Fields" backUrl={routes.dashboard}>
                <Button variant="outline-primary" onClick={() => navigate(routes.field('new'))}>
                    <PlusLg /> New field
                </Button>
            </PageHeader>
            <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={field => routes.field(field.id)} />
        </Pages.Centred>
    );
}
