import React, { useMemo } from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField} from "../../components/forms";
import {Pages, Table} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import {orderColumns} from "../../utils/orders";
import PanelSection from "../../components/layout/PanelSection";
import EntityTimestamps from "../../components/EntityTimestamps";
import {routeLabels} from "../../utils/routes";
import * as yup from "yup";

const FieldsPropTypes = {}

const FieldsDefaultProps = {}

export default function Customer() {
    const { customerId } = useParams();
    const isNew = customerId === 'new';
    const { data: orders } = useGet({ endpoint: customerId ? `/orders?customer_id=${customerId}`: '' });
    const { data: customer, isLoading: isLoadingItem, error: itemsError } = useGet({ endpoint: !isNew ? `/customers/${customerId}`: '' });
    const navigate = useNavigate();

    const memoData = useMemo(() => Array.isArray(orders) ? orders : [], [orders]);
    const memoColumns = useMemo(() => orderColumns(), []);

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.customers, label: routeLabels.customers },
                { href: '', label: isNew ? 'Creating customer': customer?.id },
            ]}
            padded={false}
        >
            <PageHeader
                header={isNew ? 'Creating new customer' : `Editing Customer: ${customer?.first_name || '-'}`}
                backUrl={routes.customers}
            />
            <div className="d-flex gap-4">
                <div className="w-25">
                    <PanelSection>
                        <h3>Customer details</h3>
                        {isLoadingItem && <Loading />}
                        {(customer || isNew) && (
                            <BackendForm
                                targetEndpoint={isNew ? '/customers' : `/customers/${customerId}`}
                                targetMethod={isNew ? 'POST': 'PUT'}
                                initialValues={{
                                    first_name: customer?.first_name || '',
                                    last_name: customer?.last_name ||'',
                                    phone: customer?.phone || '',
                                }}
                                validationSchema={yup.object({
                                    first_name: yup.string().required('Please enter a first name or initials'),
                                    last_name: yup.string().required('Please enter a last name or initials'),
                                    phone: yup.string(),
                                })}
                                enableReinitialize
                                onSuccess={(resp) => {
                                    const id = resp?.data?.id;
                                    navigate(id ? routes.customer(id) : routes.fields)
                                }}
                                showFooter
                            >
                                <FloatingLabelField name="first_name" label="First name" />
                                <FloatingLabelField name="last_name" label="Last name" />
                                <FloatingLabelField name="phone" label="Phone" />
                            </BackendForm>
                        )}
                    </PanelSection>
                    <PanelSection><EntityTimestamps entity={customer} /></PanelSection>
                </div>
                <div className="flex-grow-1">
                    <h3>Orders</h3>
                    {Array.isArray(orders) && (
                        <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={order => routes.order(order.id)} />
                    )}
                </div>
            </div>
        </Pages.Centred>
    );
}

Customer.propTypes = FieldsPropTypes;
Customer.defaultProps = FieldsDefaultProps;
