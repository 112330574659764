import React from 'react';
import styles from "./Fold.module.scss";

const FoldMainPropTypes = {}

const FoldMainDefaultProps = {}

function FoldMain({children, backgroundImage, className = '' }) {
    return (
        <div
            className={`text-center ${styles.FoldMain} ${className}`}
            style={backgroundImage ? {backgroundImage: `url(${backgroundImage})`} : undefined}
        >
            {children}
        </div>
    );
}

FoldMain.propTypes = FoldMainPropTypes;
FoldMain.defaultProps = FoldMainDefaultProps;

const FoldExtensionPropTypes = {}

const FoldExtensionDefaultProps = {}

function FoldExtension({children, backgroundColor }) {
    return (
        <div
            className={`text-center ${styles.FoldExtension}`}
            style={backgroundColor ? { backgroundColor } : undefined}
        >
            {children}
        </div>
    );
}

FoldExtension.propTypes = FoldExtensionPropTypes;
FoldExtension.defaultProps = FoldExtensionDefaultProps;

function Fold({children, backgroundImage, className = '' }) {
    return (
        <div
            className={`text-center ${styles.Fold} ${className}`}
            style={backgroundImage ? {backgroundImage: `url(${backgroundImage})`} : undefined}
        >
            {children}
        </div>
    )
}

Fold.Main = FoldMain
Fold.Extension = FoldExtension


export default Fold

