import React from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField, SelectField} from "../../components/forms";
import {Pages} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import useGet from "../../components/hooks/useGet";
import RadioBoxes from "../../components/forms/RadioBoxes";
import {availableInKioskOptions, locationOptions} from "../../components/locations/locationUtils";
import PanelSection from "../../components/layout/PanelSection";
import EntityTimestamps from "../../components/EntityTimestamps";
import {routeLabels} from "../../utils/routes";

export default function Location() {
    const { locationId } = useParams();
    const navigate = useNavigate();
    const isNew = locationId === 'new';
    const { data: location, isLoading, error } = useGet({ endpoint: !isNew ? `/locations/${locationId}`: '' });

    return (
        <Pages.SideBar
            inverse
            sidebar={<PanelSection><EntityTimestamps entity={location} /></PanelSection>}
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.locations, label: routeLabels.locations },
                { href: '', label: isNew ? 'Creating location' : location?.id },
            ]}
        >
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            {(isNew || location) && (
                <BackendForm
                    targetEndpoint={isNew ? '/locations' : `/locations/${locationId}`}
                    targetMethod={isNew ? 'POST': 'PUT'}
                    initialValues={location || {
                        name: '',
                        description: '',
                        type: locationOptions.STATIC.value,
                        available_in_kiosk: availableInKioskOptions.NO.value
                    }}
                    enableReinitialize
                    onSuccess={(resp) => {
                        const id = resp?.data?.id;
                        navigate(id ? routes.location(id) : routes.locations)
                    }}
                >
                    <PageHeader
                        header={isNew ? 'Creating new location' : `Editing location: ${location?.name}`}
                        backUrl={routes.locations}
                    />
                    <RadioBoxes
                        name="type"
                        label="Type"
                        options={Object.values(locationOptions)}
                    />
                    <RadioBoxes
                        name="available_in_kiosk"
                        label="Available in kiosk"
                        options={Object.values(availableInKioskOptions)}
                    />
                    <FloatingLabelField name="name" label="Name" />
                    <FloatingLabelField
                        name="description"
                        label="Description"
                        helperText="This is for internal referencing. To explain to other employees what this is."
                    />
                </BackendForm>
            )}
        </Pages.SideBar>
    );
}
