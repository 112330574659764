import React from "react";
import moment from "moment";
import {standardDateTimeFormat} from "./momentHelpers";
import {computeTotal} from "../components/pos/kiosk/KioskHelpers";
import CurrencyAmount from "../components/layout/CurrencyAmount";
import TotalsPerCurrencyBadge from "../components/layout/TotalsPerCurrencyBadge";
import {collect} from "./hashMap";


export function orderColumns() {
    return [
        {
            header: 'Checkout date',
            accessor: 'created_at',
            Cell: ({ value }) => moment(value).format(standardDateTimeFormat),
        },
        { accessor: 'customer', header: 'Customer', Cell: ({ value: customer }) => customer ? `${customer.first_name || ''} ${customer.last_name || ''}` : '-' },
        { accessor: 'order_line_items.length', header: '# items' },
        { accessor: 'created_by.name', header: 'Employee' },
        { accessor: 'discount_rate', header: 'Applied discount', Cell: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '-' },
        { accessor: 'order_line_items', header: 'Total Value', Cell: ({ value, row }) => {
            return <>
                <CurrencyAmount amount={computeTotal(row.original)} />
                &nbsp;
                <TotalsPerCurrencyBadge orders={[row.original]} />
            </>;
        }, },
        { accessor: 'open_debt', header: 'Open debt', Cell: ({ value }) => {
                return value !== 0 ? <span className="text-danger"><CurrencyAmount amount={parseFloat(value).toFixed(2)} /></span> : <small>Paid off</small>;
            }, },
        { accessor: 'id', header: 'ID' },
    ]
}

/**
 *
 * @param orders {Array}
 * @param currencies {Array}
 * @return {{}}
 */
export function getTotalsPerCurrency(orders, currencies) {
    // All currencies should have a value at every date
    const totalsPerCurrencyOnDate = {};
    currencies?.forEach((curr) => totalsPerCurrencyOnDate[curr] = 0)
    if (Array.isArray(orders)) {
        // Compute the total per item per date
        const perCurrency = collect(orders.map(o => o.credit_balance).flat(1), 'checked_out_currency');
        for (const curr in perCurrency) {
            totalsPerCurrencyOnDate[curr] = perCurrency[curr].reduce(
                (acc, o) => acc + (parseFloat(o.checked_out_amount) || 0),
                0
            )
        }
    }
    return totalsPerCurrencyOnDate;
}
