import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import {Pages, Table} from "../../components/layout";
import {useNavigate} from "react-router";
import Button from "react-bootstrap/Button";
import {routes} from "../../utils";
import {PlusLg} from "react-bootstrap-icons";
import { useItemGroups } from "../../components/hooks/useItem";
import PageHeader from "../../components/layout/PageHeader";
import {Alert} from "react-bootstrap";
import Loading from "../../components/Loading";
import {routeLabels} from "../../utils/routes";


export default function ItemGroups() {
    const { itemsGroups, error, isLoading } = useItemGroups();
    const navigate = useNavigate();

    const memoData = useMemo(() => Array.isArray(itemsGroups) ? itemsGroups : [], [itemsGroups]);
    const memoColumns = useMemo(() => [
        { accessor: 'name', header: 'Item Group name' },
        { accessor: 'items_count', header: 'Number of items' },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.itemGroups, label: routeLabels.itemGroups },
            ]}
            padded={false}
        >
            <PageHeader header="All item groups" backUrl={routes.dashboard}>
                <Button variant="outline-primary" onClick={() => navigate(routes.itemGroup('new'))}>
                    <PlusLg /> New Item Group
                </Button>
            </PageHeader>
            {error && <Alert>{error}</Alert>}
            {isLoading
                ? <Loading />
                : (
                    <Table
                        memoisedData={memoData}
                        memoisedColumns={memoColumns}
                        buildLink={item => routes.itemGroup(item.id)}
                    />
                )}
        </Pages.Centred>
    );
}

