import React from 'react';
import {routes} from "../utils";
import {concepts} from "../libs/lang";
import useAuth from "./hooks/useAuth";

export default function Logo() {
    const { user } = useAuth();

    return (
        <a href={user ? (user.is_admin ? routes.dashboard : routes.kiosk) : routes.login} className="text-center me-3 text-light text-decoration-none fw-bold">
            {concepts.AppName}
        </a>
    );
}
