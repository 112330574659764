import React from 'react';
import {useKioskContext} from "./KioskContext";
import CurrencyAmount from "../../layout/CurrencyAmount";
import DiscountModal from "./DiscountModal";
import OrderListRow from "./OrderListRow";

export default function OrderList() {
    const {
        itemsInCart,
        quantities,
        decreaseItem,
        increaseItem,
        setQuantity,
        totals,
        discountRate,
        setDiscountRate,
    } = useKioskContext();

    return (
        <div className="d-flex flex-column flex-grow-1 bg-dark mb-2" style={{ minWidth: 355 }}>
            <div className="flex-grow-1">
                <table className="table table-dark table-sm">
                    <thead>
                    <tr>
                        {['Item', 'Qty', 'Price', 'Subtotal'].map((header) => (
                            <th key={header} className="fw-bold pe-2">{header}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {itemsInCart.length ? Object.keys(quantities).map((key) => {
                        const [itemId, priceId] = key.split('-');
                        const qty = quantities[key];
                        const item = itemsInCart.find(i => i.id == itemId);
                        const priceObject = item.prices.find(p => p.id == priceId);
                        const priceAmount = parseFloat(priceObject.amount);
                        const subtotal = parseFloat(priceAmount * qty);

                        return (
                            <OrderListRow
                                key={item.id}
                                item={item}
                                finalQty={qty}
                                decreaseItem={decreaseItem}
                                increaseItem={increaseItem}
                                subtotal={subtotal}
                                priceObject={priceObject}
                                setQuantity={setQuantity}
                                priceAmount={priceAmount}
                            />
                        );
                    }) : <tr><td colSpan={4} className="p-4">No items in cart yet</td></tr>}
                    </tbody>
                </table>
            </div>
            <div>
                <table className="table table-sm table-borderless table-dark mb-0">
                    <tfoot className="bg-light border-top">
                    <tr className="text-xl" valign="bottom">
                        <td colSpan={3}>Subtotal</td>
                        <td className="text-right">
                            <CurrencyAmount amount={totals.subtotal.toFixed(2)} />
                        </td>
                    </tr>
                    <tr className="text-xl" valign="bottom">
                        <td colSpan={3}>Discount</td>
                        <td className="text-right">
                            {(discountRate * 100).toFixed(2)}%
                            &nbsp;
                            <DiscountModal
                                discountRate={discountRate}
                                setDiscountRate={setDiscountRate}
                                baseTotal={totals.total}
                            />
                        </td>
                    </tr>
                    <tr className="text-xl" valign="bottom">
                        <td colSpan={3}>Total</td>
                        <td className="text-right">
                            <CurrencyAmount amount={totals.total.toFixed(2)} showAll />
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}
