import React from 'react';
import {Link, useParams} from "react-router-dom";
import {BackendForm} from "../../components/forms";
import {Pages} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import {Alert, Col, Row} from "react-bootstrap";
import OrderLineItemsList from "../../components/orders/OrderLineItemsList";
import CurrencyAmount from "../../components/layout/CurrencyAmount";
import Description from "../../components/Description";
import PanelSection from "../../components/layout/PanelSection";
import OrderInformation from "../../components/orders/OrderInformation";
import {routeLabels} from "../../utils/routes";
import {useDeleteModal} from "../../utils/useDeleteModal";
import Button from "react-bootstrap/Button";
import {backendEndpoints} from "../../libs/backendEndpoints";
import MiniPaymentsTable from "../../components/pos/kiosk/MiniPaymentsTable";


export default function Order() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const { modal, deleteButtonCall } = useDeleteModal({
        endpoint: orderId ? backendEndpoints.deleteOrder(orderId) : '',
        onSuccess: () => navigate(routes.orders)
    });
    const isNew = orderId === 'new';
    const { data: item, isLoading: isLoadingItem, error } = useGet({ endpoint: !isNew ? `/orders/${orderId}`: '' });
    const openDebt = item?.open_debt;

    return (
        <>
            {modal}
            <Pages.Centred
                breadcrumbs={[
                    { href: routes.dashboard, label: routeLabels.dashboard },
                    { href: routes.orders, label: routeLabels.orders },
                    { href: '', label: isNew ? 'Creating new': 'Viewing order' },
                ]}
                padded={false}
            >
                {error && <Alert variant="error">{error}</Alert>}
                {isLoadingItem ? <Loading /> : (
                    <BackendForm
                        targetEndpoint={isNew ? '/orders' : `/orders/${orderId}`}
                        targetMethod={isNew ? 'POST': 'PUT'}
                        initialValues={item}
                        onSuccess={(resp) => {
                            const id = resp?.data?.id;
                            navigate(id ? routes.order(id) : routes.fields)
                        }}
                        showFooter={false}
                    >
                        <PageHeader
                            header={isNew ? 'Creating new order' : `Viewing order #${item?.id}`}
                            backUrl={routes.orders}
                        >
                            {deleteButtonCall && (
                                <Button onClick={() => deleteButtonCall()}>Delete</Button>
                            )}
                        </PageHeader>
                        <Row>
                            <Col md={5}>
                                <PanelSection>
                                    <h3>General details.</h3>
                                    <OrderInformation order={item} />
                                </PanelSection>
                                <PanelSection>
                                    <h3 className="mt-4">{openDebt > 0 ? 'Debt' : 'Pay back'}: <CurrencyAmount amount={Math.abs(openDebt)} /></h3>
                                    <Description>
                                        To modify the credit of this order, please go into
                                        the <Link to={routes.kiosk}>kiosk</Link>.
                                    </Description>
                                    {openDebt > 0 && <Alert>
                                        The customer still needs to pay: <br />
                                        <CurrencyAmount amount={openDebt} showAll seperator="Or " />
                                    </Alert>}
                                    <MiniPaymentsTable items={item?.credit_balance} />
                                </PanelSection>
                            </Col>
                            <Col md={7}>
                                <h3>Order items.</h3>
                                <Description>
                                    Here you can see all items of this order.
                                    To <b>return items</b>, please go into
                                    the <Link to={routes.kiosk}>kiosk</Link>.
                                </Description>
                                <OrderLineItemsList name="order_line_items" viewOnly />
                            </Col>
                        </Row>
                    </BackendForm>
                )}
            </Pages.Centred>
        </>
    );
}

