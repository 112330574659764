import Layout from "./components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router";
import { Toaster } from "react-hot-toast";
import Login from "./pages/Login";
import { routes } from "./utils";
import {AuthProvider} from "./components/hooks/useAuth";
import Logout from "./pages/Logout";
import {QueryClient, QueryClientProvider} from 'react-query'
import AccountForm from "./pages/AccountForm";
import SignUp from "./pages/SignUp";
import SignUpSuccess from "./pages/SignUpSuccess";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";
import EmailVerified from "./pages/EmailVerified";
import CompleteAccount from "./pages/CompleteAccount";
import CompleteAccountSuccess from "./pages/CompleteAccountSuccess";
import Dashboard from "./pages/Dashboard";
import TwoFactorAuthentication from "./pages/2fa/TwoFactorAuthentication";
import TwoFactorAuthenticationSuccess from "./pages/2fa/TwoFactorAuthenticationSuccess";
import ProtectedRoute from "./pages/guards/ProtectedRoute";
import TwoFactorChallenge from "./pages/TwoFactorChallenge";
import Fields from "./pages/backend/Fields";
import Items from "./pages/backend/Items";
import Field from "./pages/backend/Field";
import Item from "./pages/backend/Item";
import Kiosk from "./components/pos/kiosk/Kiosk";
import Orders from "./pages/backend/Orders";
import Order from "./pages/backend/Order";
import Customers from "./pages/backend/Customers";
import Customer from "./pages/backend/Customer";
import Locations from "./pages/backend/Locations";
import Location from "./pages/backend/Location";
import ItemGroups from "./pages/backend/ItemGroups";
import ItemGroup from "./pages/backend/ItemGroup";
import Organisation from "./pages/backend/Organisation";
import Users from "./pages/backend/Users";
import User from "./pages/backend/User";
import KioskContextProvider from "./components/pos/kiosk/KioskContext";

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 5*60*1000,
        },
    },
})

function App() {
    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <Toaster position="top-center" reverseOrder={true} />
                <Routes>
                    <Route path={routes.kiosk} element={<KioskContextProvider><Kiosk /></KioskContextProvider>} />
                    <Route
                        path="*"
                        element={(
                            <Layout>
                                <Routes>
                                    <Route path={routes.userSettings} element={<ProtectedRoute><AccountForm /></ProtectedRoute>} />
                                    <Route path={routes.organisation} element={<ProtectedRoute adminOnly><Organisation /></ProtectedRoute>} />
                                    <Route path={routes.twoFactorAuthentication} element={<ProtectedRoute><TwoFactorAuthentication /></ProtectedRoute>} />
                                    <Route path={routes.twoFactorAuthenticationSuccess} element={<ProtectedRoute><TwoFactorAuthenticationSuccess /></ProtectedRoute>} />
                                    <Route path={routes.twoFactorChallenge} element={<ProtectedRoute><TwoFactorChallenge /></ProtectedRoute>} />
                                    <Route path={routes.dashboard} element={<ProtectedRoute adminOnly><Dashboard /></ProtectedRoute>} />
                                    <Route path={routes.customers} element={<ProtectedRoute adminOnly><Customers /></ProtectedRoute>} />
                                    <Route path={routes.user(':userId')} element={<ProtectedRoute adminOnly><User /></ProtectedRoute>} />
                                    <Route path={routes.users} element={<ProtectedRoute adminOnly><Users /></ProtectedRoute>} />
                                    <Route path={routes.customer(':customerId')} element={<ProtectedRoute adminOnly><Customer /></ProtectedRoute>} />
                                    <Route path={routes.item(':itemId')} element={<ProtectedRoute adminOnly><Item /></ProtectedRoute>} />
                                    <Route path={routes.items} element={<ProtectedRoute adminOnly><Items /></ProtectedRoute>} />
                                    <Route path={routes.itemGroup(':itemGroupId')} element={<ProtectedRoute adminOnly><ItemGroup /></ProtectedRoute>} />
                                    <Route path={routes.itemGroups} element={<ProtectedRoute adminOnly><ItemGroups /></ProtectedRoute>} />
                                    <Route path={routes.field(':fieldId')} element={<ProtectedRoute adminOnly><Field /></ProtectedRoute>} />
                                    <Route path={routes.fields} element={<ProtectedRoute adminOnly><Fields /></ProtectedRoute>} />
                                    <Route path={routes.location(':locationId')} element={<ProtectedRoute adminOnly><Location /></ProtectedRoute>} />
                                    <Route path={routes.locations} element={<ProtectedRoute adminOnly><Locations /></ProtectedRoute>} />
                                    <Route path={routes.order(':orderId')} element={<ProtectedRoute adminOnly><Order /></ProtectedRoute>} />
                                    <Route path={routes.orders} element={<ProtectedRoute adminOnly><Orders /></ProtectedRoute>} />
                                    <Route path={routes.forgotPassword()} element={<ForgotPassword />} />
                                    <Route path={routes.resetPassword} element={<ResetPassword />} />
                                    <Route path={routes.completeAccount(':userId', ':hash')} element={<CompleteAccount />} />
                                    <Route path={routes.emailVerified} element={<EmailVerified />} />
                                    <Route path={routes.signUpSuccess} element={<SignUpSuccess />} />
                                    <Route path={routes.completeAccountSuccess} element={<CompleteAccountSuccess />} />
                                    <Route path={routes.signUp} element={<SignUp />} />
                                    <Route path={routes.logout} element={<Logout />} />
                                    <Route path={routes.login} element={<Login />} />
                                    <Route path="/" element={<Login />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Layout>
                        )}
                    />
                </Routes>
            </QueryClientProvider>
        </AuthProvider>
    );
}

export default App;
