import React, {useState, useContext, useMemo} from 'react';
import _ from 'lodash';
import axios from "axios";
import toast from "react-hot-toast";
import useLocations from "../../hooks/useLocations";
import {availableInKioskOptions, locationOptions} from "../../locations/locationUtils";
import {Alert, CloseButton} from "react-bootstrap";
import {Trash} from "react-bootstrap-icons";
import {convertAmount} from "../../layout/CurrencyAmount";
import useAuth from "../../hooks/useAuth";
import Loading from "../../Loading";
import Spinner from "react-bootstrap/Spinner";

const TOP_THRESHOLD = 1.05;

const KioskContext = new React.createContext({
    increaseItem: (item, price) => {},
    decreaseItem: (item, price) => {},
    quantities: {},
    checkout: (method, data = {}) => {},
    discountRate: 0,
    setDiscountRate: () => {},
    totals: { total: 0, subtotal: 0 },
})

export function useKioskContext() {
    return useContext(KioskContext);
}

export default function KioskContextProvider({ children }) {
    const { user } = useAuth();
    const [itemsInCart, setItemsInCart] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [location, setLocation] = useState();
    const [discountRate, setDiscountRate] = useState(0);
    const { locations, isLoading: loadingLocations } = useLocations({
        queryParams: `available_in_kiosk=${availableInKioskOptions.YES.value}&type=${locationOptions.STATIC.value}`
    });

    const comb = (item, price) => `${item.id}-${price.id}`;

    const increaseItem = (item, price) => {
        if (!itemsInCart.find((i) => i.id === item.id)) {
            setItemsInCart(itemsInCart.concat([item]));
        }
        const key = comb(item, price);
        setQuantities({
            ...quantities,
            [key]: (quantities[key] || 0) + 1,
        });
        toast.success((t) => (
            <div className="d-flex">
                Added ${item?.name} to cart.
                <button
                    onClick={() => toast.dismiss(t.id)}
                    className="border border-transparent rounded-circle p-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                    <CloseButton />
                </button>
            </div>
        ))
    };
    const decreaseItem = (item, price) => {
        const key = comb(item, price);
        if (itemsInCart.find((i) => i.id === item.id)) {
            const newQty = (quantities[key] || 0) - 1;
            if (newQty <= 0) {
                deleteItem(item, price);
            } else {
                setQuantities({...quantities, [key]: newQty });
            }
        }
    };
    const setQuantity = (item, price, newQty) => {
        const key = comb(item, price);
        if (itemsInCart.find((i) => i.id === item.id)) {
            setQuantities({...quantities, [key]: newQty });
        }
    }
    const deleteItem = (item, price) => {
        const key = comb(item, price);
        setQuantities(_.omit(quantities, key));
        const itemIdsInQuantities = Object.keys(quantities)
            .map(key => parseInt(key.split('-')[0], 10));
        if (itemIdsInQuantities.indexOf(item.id) === -1) {
            setItemsInCart(itemsInCart.filter((i) => i.id !== item.id));
        }
    };
    const reset = () => {
        setItemsInCart([]);
        setQuantities({});
        setDiscountRate(0);
    };
    const checkout = (proces, additional_data = {}) => {
        const data = {
            quantities,
            payment_method: proces,
            location_id: location.id,
            additional_data,
        };
        return axios.post('/kiosk/checkout', data)
            .then((resp) => {
                reset();
                return resp;
            })
    };

    const memoTotals = useMemo(() => {
        const totals = {
            total: 0,
            subtotal: 0,
            vat: 0,
        };
        Object.keys(quantities).forEach((key) => {
            const [itemId, priceId] = key.split('-');
            const qty = quantities[key];
            const item = itemsInCart.find(i => i.id == itemId);
            const priceObject = item.prices.find(p => p.id == priceId);
            const priceAmount = parseFloat(priceObject.amount);
            const subtotal = priceAmount * parseFloat(qty);

            totals.subtotal += subtotal;
            totals.total += subtotal * (1 - discountRate);
        });
        return totals;
    }, [quantities, itemsInCart, discountRate]);
    const hasLocations = locations?.length > 0;

    const isValidNumber = (amountPaid) => {
        const float = parseFloat(amountPaid);
        return typeof float === 'number' && float >= 0;
    }
    const isValidAmount = (amountPaid, currency) => {
        const float = parseFloat(amountPaid);
        return isValidNumber(amountPaid) &&  float <= convertAmount(parseFloat(memoTotals.total), user, currency) * TOP_THRESHOLD;
    }

    return (
        <KioskContext.Provider value={{
            increaseItem,
            decreaseItem,
            deleteItem,
            itemsInCart,
            quantities,
            checkout,
            totals: memoTotals,
            setLocation: (locationObject) => {
                reset();
                setLocation(locationObject);
            },
            setQuantity,
            isValidAmount,
            isValidNumber,
            locations,
            location,
            setDiscountRate,
            discountRate,
        }}>
            {loadingLocations
                ? <div className="bg-dark d-inline-flex flex-column vh-100 vw-100 align-items-center align-content-center justify-content-center text-xl text-light">
                    <Spinner animation="border" size="lg" />
                    <div>Loading kiosk...</div>
                </div>: (
                hasLocations ? children : (
                    <div className="bg-dark d-inline-flex vh-100 vw-100 align-items-center align-content-center justify-content-center">
                        {!hasLocations && <Alert variant="warning">Please ask your admin to create at least <b>1 location</b> that is <b>available for kiosk</b>.</Alert>}
                    </div>
                )
            )}
        </KioskContext.Provider>
    );
}
