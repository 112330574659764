import {routes} from "../../utils";
import React from "react";
import _ from "lodash";
import {hashMap} from "../../utils/hashMap";

class Mutation {
    constructor({date, note, change, type, entry, orderLineItem, location_type }) {
        this.date = date;
        this.note = note;
        this.change = change;
        this.type = type;
        this.entry = entry;
        this.location_type = location_type;
        this.orderLineItem = orderLineItem;

    }
}

export function computeStock({ locations, stockEntries, orderLineItems }, perItem = false) {
    const list = {};
    if (Array.isArray(locations)) {
        for (let i = 0; i < locations?.length; i++) {
            list[locations[i].id] = perItem ? {} : [];
        }
        if (Array.isArray(stockEntries)) {
            for (let i = 0; i < stockEntries.length; i++) {
                const entry = stockEntries[i];
                if (perItem && !list[entry.location_id][entry.item_id]) list[entry.location_id][entry.item_id] = [];
                const target = perItem ? list[entry.location_id][entry.item_id] : list[entry.location_id];
                target.push(new Mutation({
                    note: entry.note,
                    change: parseFloat(entry.change),
                    date: entry.created_at,
                    type: entry.sequence_id ? 'SHIPMENT' : 'EDIT',
                    entry,
                }));
            }
        }
        if (Array.isArray(orderLineItems)) {
            for (let i = 0; i < orderLineItems.length; i++) {
                const line = orderLineItems[i];
                if (perItem && !list[line.location_id][line.item_id]) list[line.location_id][line.item_id] = [];
                const target = perItem ? list[line.location_id][line.item_id] : list[line.location_id];
                target.push(new Mutation({
                    note: <a href={routes.order(line.order_id)}>Order ID {line.order_id}</a>,
                    change: parseFloat(line.quantity) * parseFloat(line.price?.stock_mutation),
                    date: line.created_at,
                    type: 'SALE',
                    orderlineItem: line,
                }));
            }
        }
    }
    return list;
}

export function computeTotalStock(mutations) {
    const totals = {
        current: 0,
        upcoming: 0,
    };
    for (let i = 0; i < mutations?.length; i++) {
        const m = mutations[i];
        if (m.type === 'SALE' || m.entry?.realised) {
            totals.current += m.change;
        } else {
            totals.upcoming += m.change;
        }
    }

    return totals;
}


