import React from 'react';
import Button from "react-bootstrap/Button";
import CurrencyAmount from "../../layout/CurrencyAmount";
import Loading from "../../Loading";

export default function KioskButton({ title, onClick, cornerInfo, className = '', disabled, imageUrl, isBusy, maxWidth }) {
    const opacity = disabled ? 0.3 : 1;
    return (
        <Button
            className={`px-3 py-5 mb-2 bg-gradient-color-inverse text-light border border-0 rounded-0 position-relative flex-grow-1 w-100 shadow ${className}`}
            onClick={onClick}
            disabled={disabled}
            style={ imageUrl ? {
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                textShadow: '1px 1px 4px #000',
                maxWidth,
                opacity,
            } : { textShadow: '1px 1px 4px #000', maxWidth, opacity }}
        >
            <div className="fw-bold text-capitalize">
                {isBusy ? <Loading /> : <span style={imageUrl ? { padding: '7px 10px', backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius: '7px' } : {}}>{title}</span>}
            </div>
            {cornerInfo && (
                <div
                    className="position-absolute bottom-0 end-0"
                    style={{ padding: '7px 10px', backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: '7px 0 7px 0' }}
                >
                    {cornerInfo}
                </div>
            )}
        </Button>
    );
}

function OrderButton({ order, onClick }) {
    return (
        <KioskButton
            title={order.title}
            cornerInfo={order.open_debt ? <>Open debt: <CurrencyAmount amount={order.open_debt} /></> : null}
            className="w-100 py-1"
            onClick={onClick}
        />
    );
}

KioskButton.Order = OrderButton;
