import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from "./layout/Layout.module.scss";

const ProfilePicturePropTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
}

const ProfilePictureDefaultProps = {
    className: '',
    size: undefined,
}

export default function ProfilePicture({ user, size, className }) {
    const url = user.image?.url;
    return url
        ? (
            <span
                className={[styles.UserImage, className].join(' ')}
                style={{ backgroundImage: `url(${url})`, paddingTop: size, width: size }}
            >
                &nbsp;
            </span>
        )
        : (
            <span
                className={[styles.UserImage, className].join(' ')}
                style={{ paddingTop: size, width: size }}
            >
                <span className={styles.Initials}>
                    {user.name[0]}
                </span>
            </span>
        );
}

ProfilePicture.propTypes = ProfilePicturePropTypes;
ProfilePicture.defaultProps = ProfilePictureDefaultProps;
