import {Pages} from "../components/layout";
import Description from "../components/Description";
import useTitle from "../components/hooks/useTitle";

export default function NotFound() {
    useTitle('404 not found')
    return (
        <Pages.Centred padded width="xs">
            <h1 className="gradient-color">Page not found.</h1>
            <Description>
                Error 404: this page does not exist.
            </Description>
        </Pages.Centred>
    )
}
