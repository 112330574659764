export const locationOptions = {
    STATIC: {value: '1', label: 'Static site', helperText: 'Use this for any of your stores or warehouses.'},
    TRANSPORT: {
        value: '2',
        label: 'Transport Line',
        helperText: 'For instance: Surinam - Netherlands (Aircargo) or China -Surinam (Seacargo)'
    }
}

export const availableInKioskOptions = {
    NO: {value: '1', label: 'Not available for kiosk'},
    YES: {value: '2', label: 'Available for kiosk'}
}
