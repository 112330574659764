import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {Alert, Modal} from "react-bootstrap";
import {
    BackendForm,
    DynamicList,
    FloatingLabelField,
    Select,
    SelectField,
    CheckBox, RadioBoxes
} from "../components/forms";
import useLocations from "../components/hooks/useLocations";
import {useItem, useItems} from "../components/hooks/useItem";
import {inputClasses} from "../components/forms/inputClasses";
import {recordsToOptions} from "../components/forms/SelectField";
import {Building, Truck} from "react-bootstrap-icons";
import Loading from "../components/Loading";
import moment from "moment";
import toast from "react-hot-toast";
import StockMutations from "../components/stock/StockMutations";
import {OptionBar} from "../components/layout";

const OPEN_VALUE = -1
export function useModifyStockModal({ inKiosk }) {
    const [itemId, setItemId] = useState('');
    const modal = itemId && <ModifyStockModal itemId={itemId} close={() => setItemId('')} inKiosk={inKiosk} />;
    return { openForItem: (itemId) => setItemId(itemId), itemId, open: () => setItemId(OPEN_VALUE), modal };
}


const ModifyStockModalPropTypes = {}

const ModifyStockModalDefaultProps = {}

const confirmationOptions = {
    ALREADY: {value: '0', label: 'Already here'},
    REQUIRES: {value: '1', label: 'To be received'},
};

const emptyEntry = { location_id: '', change: '', needs_confirmation: confirmationOptions.REQUIRES.value };

function ModifyStockModal({ itemId: fixedItemId, close, inKiosk }) {
    const { locations, isLoading: isLoadingLocations, error: locationsError } = useLocations();
    const [localItemId, setLocalItemId] = useState(fixedItemId)
    const [isSequence, setSequence] = useState(false)
    const itemSelectEnabled = fixedItemId === OPEN_VALUE;
    const { item: fixedItem, isLoading: isLoadingItem, error: itemError } = useItem(itemSelectEnabled ? '' : fixedItemId);
    const { items, error: itemsError, isLoading: isLoadingItems } = useItems({ disabled: !itemSelectEnabled });
    const error = itemsError || locationsError || itemError;
    const isLoading = isLoadingLocations || isLoadingItem || isLoadingItems;

    const selectedItem = itemSelectEnabled ? items?.find(i => i.id === localItemId) : fixedItem;

    const Component = useCallback(({ item, name, index, prevItem }) => {
        const shouldBeTransportLocation = index % 2 === 1;
        const locationOptions = recordsToOptions(locations.filter(l => !isSequence || l.type === (shouldBeTransportLocation ? 2 : 1)))
        const changeField = <FloatingLabelField name={`${name}.change`} label="Change" helperText="e.g. -120" />;
        return isSequence ? (
            <div className="d-flex gap-2" key="sequence">
                <div className="h2">{shouldBeTransportLocation ? <Truck /> : <Building />}</div>
                <div className="w-50">
                    <SelectField
                        name={`${name}.location_id`}
                        label={shouldBeTransportLocation ? 'Transport' : 'Site'}
                        options={locationOptions}
                        mandatory
                    />
                </div>
                <div className="flex-grow-1" style={{ marginBottom: '-10px' }}>
                    {index === 0
                        ? changeField
                        : (
                            (prevItem?.change || prevItem?.needs_confirmation === confirmationOptions.ALREADY.value)
                                && <RadioBoxes name={`${name}.needs_confirmation`} label="" options={Object.values(confirmationOptions)} mandatory />
                        )}
                </div>
            </div>
        ) : (
            <div className="d-flex gap-2" key="not-sequence">
                <div className="w-50">
                    <SelectField name={`${name}.location_id`} label="Location" options={locationOptions} mandatory />
                </div>
                <div className="flex-grow-1" style={{ marginBottom: '-10px' }}>
                    <FloatingLabelField name={`${name}.change`} label="Change" helperText="e.g. -10 of 50" />
                </div>
            </div>
        )
    }, [locations, isSequence]);

    return (
        <Modal show onHide={close} size={itemSelectEnabled ? 'lg' : 'sm'}>
            <Modal.Header closeButton>
                <Modal.Title>Modify stock</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {itemSelectEnabled ? (
                    <OptionBar stretch negativeMargin>
                        <OptionBar.Item transparent label="Item">
                            <Select
                                options={recordsToOptions(items)}
                                value={localItemId}
                                className={inKiosk ? inputClasses.kioskSelectFullWidth : 'form-control'}
                                onChange={(e) => setLocalItemId(parseInt(e.target.value, 10))}
                            />
                        </OptionBar.Item>
                    </OptionBar>
                ) : (
                    <h2>Item: {selectedItem?.name}</h2>
                )}
                {selectedItem && (
                    <div className="d-flex gap-3 mt-5">
                        <div className="w-50 flex-grow-1">
                            <h4>Add new stock</h4>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {isLoading && <Loading />}
                            {localItemId > 0 && !isLoading && (
                                <>
                                    <hr />
                                    <CheckBox name="is_sequence" label="Create a shipment" checked={isSequence} onChange={(e) => setSequence(e.target.checked)} />
                                    <hr />
                                    <BackendForm
                                        targetEndpoint={`/items/${localItemId}?_with[]=stock_entries`}
                                        targetMethod="PUT"
                                        initialValues={{
                                            stock_entries: [emptyEntry],
                                            note: '',
                                        }}
                                        onSuccess={(resp) => {
                                            close();
                                            if (typeof resp?.message === 'string') {
                                                toast.success(resp.message);
                                            }
                                        }}
                                        modifyValuesPreSubmit={(values) => {
                                            const confirmedAt = isSequence ? true : null;
                                            const sequenceId = isSequence ? uuidv4() : null;
                                            const stockEntries = [];
                                            const lastIndex = values.stock_entries.length - 1;
                                            const firstChange = values.stock_entries[0].change;
                                            let forceRequiresConfirmation = false;
                                            values.stock_entries.forEach((entry, index) => {
                                                const alreadyBeenThere = !forceRequiresConfirmation && entry.needs_confirmation === confirmationOptions.ALREADY.value;

                                                const createCounter = alreadyBeenThere && index > 0;
                                                stockEntries.push({
                                                    ...entry,
                                                    note: values.note,
                                                    confirmed_at: index === 0 || alreadyBeenThere ? confirmedAt : null,
                                                    change: isSequence ? firstChange * (index > 0 ? -1 : 1) : entry.change,
                                                    sequence_id: sequenceId,
                                                });

                                                if (createCounter && index !== lastIndex) {
                                                    stockEntries.push({ ...stockEntries[stockEntries.length - 1] })
                                                    stockEntries[stockEntries.length - 1].change *= -1;
                                                }

                                                if (isSequence && index > 0 && !forceRequiresConfirmation && entry.needs_confirmation === confirmationOptions.REQUIRES.value) {
                                                    forceRequiresConfirmation = true;
                                                }
                                            });
                                            values.stock_entries = stockEntries;
                                            delete values.note;
                                            return values;
                                        }}
                                    >
                                        <DynamicList
                                            name="stock_entries"
                                            addButton="entry"
                                            emptyEntry={emptyEntry}
                                            Component={Component}
                                            maxSize={isSequence ? 3 : undefined}
                                            minSize={isSequence ? 3 : undefined}
                                        />
                                        <div className="my-5" />
                                        <FloatingLabelField name="note" label="Note" />
                                    </BackendForm>
                                </>
                            )}
                        </div>
                        <div className="w-50 flex-grow-1 border-start border-dark ps-3">
                            <h4>Stock information</h4>
                            <StockMutations itemId={selectedItem?.id} />
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

ModifyStockModal.propTypes = ModifyStockModalPropTypes;
ModifyStockModal.defaultProps = ModifyStockModalDefaultProps;
