import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import KioskButton from "../pos/kiosk/KioskButton";

export default function SearchResultButton() {
}

SearchResultButton.Order = ({ onClick, order }) => {
    return (
        <KioskButton
            title={order.title}
            cornerInfo={order.context}
            onClick={onClick}
            className="w-100 py-1"
        />
    );
}
