import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CurrencyAmount from "../../layout/CurrencyAmount";

const MiniOrderTablePropTypes = {}

const MiniOrderTableDefaultProps = {}

export default function MiniOrderItemsTable({ items }) {
    return (
        <table className="table table-light table-sm text-sm">
            <thead>
                <tr>
                    <th>QTY</th>
                    <th>Item</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
            {items.map(({ item, id, quantity, total_amount: totalAmount }) => (
                <tr key={id}>
                    <td>{quantity}</td>
                    <td>{item.name}</td>
                    <td><CurrencyAmount amount={totalAmount} /></td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

MiniOrderItemsTable.propTypes = MiniOrderTablePropTypes;
MiniOrderItemsTable.defaultProps = MiniOrderTableDefaultProps;
