import React, { useMemo } from 'react';
import useGet from "../../components/hooks/useGet";
import {Pages, Table} from "../../components/layout";
import {useNavigate} from "react-router";
import Button from "react-bootstrap/Button";
import {routes} from "../../utils";
import {PlusLg} from "react-bootstrap-icons";
import {useItems} from "../../components/hooks/useItem";
import PageHeader from "../../components/layout/PageHeader";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import {routeLabels} from "../../utils/routes";

const columns = [
    { accessor: 'name', header: 'Name' },
]

const FieldsPropTypes = {}

const FieldsDefaultProps = {}

export default function Items() {
    const { items, isLoading, error } = useItems();
    const navigate = useNavigate();

    const memoData = useMemo(() => Array.isArray(items) ? items : [], [items]);
    const memoColumns = useMemo(() => [
        { accessor: 'name', header: 'Item name' },
        { accessor: 'id', header: 'ID' },
    ], []);

    return (
        <Pages.Centred
            header="Items"
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.items, label: routeLabels.items },
            ]}
            padded={false}
        >
            <PageHeader
                header="Items"
                backUrl={routes.dashboard}
            >
                <Button variant="outline-primary" onClick={() => navigate(routes.item('new'))}>
                    <PlusLg /> New Item
                </Button>
            </PageHeader>
            {error && <Alert>{error}</Alert>}
            {isLoading ? <Loading /> : <Table memoisedData={memoData} memoisedColumns={memoColumns} buildLink={item => routes.item(item.id)} />}
        </Pages.Centred>
    );
}

Items.propTypes = FieldsPropTypes;
Items.defaultProps = FieldsDefaultProps;
