import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import Loading from "../Loading";

export const tokenKey = 'authToken'

const AuthContex = createContext({
    user: null,
    isLoading: false,
    reloadUser: () => {},
    logout: false,
})

function setCookie(cname, cvalue, hours = 0.5) {
    const d = new Date();
    d.setTime(d.getTime() + (hours*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function removeCookie(cname, cvalue) {
    setCookie(cname, cvalue, -5)
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate()

    const saveToken = (newToken = undefined) => {
        if (newToken && newToken !== 'undefined' && newToken !== 'null') {
            setCookie(tokenKey, newToken)
        } else {
            removeCookie(tokenKey)
            setUser(null);
        }
    }

    const doToken = (useToken) => {
        if (useToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+useToken
            axios.get('/me', {
                data: {},
                baseURL: axios.defaults.baseURL,
                headers: {
                    'Authorization': 'Bearer '+useToken,
                    'ContentType': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(({ data }) => {
                    setUser(data.data)
                })
                .catch((e) => {
                    if ([routes.login, routes.twoFactorChallenge].indexOf(window.location.pathname) === -1) {
                        saveToken()
                        navigate(routes.login)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const loadCurrentUser = () => {
        doToken(getCookie(tokenKey));
    }

    useEffect(() => {
        if (getCookie(tokenKey)) {
            loadCurrentUser()
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <AuthContex.Provider value={{
            user,
            saveToken,
            isLoading,
            reloadUser: loadCurrentUser,
            logout: () => saveToken(undefined),
        }}>
            {isLoading ? <Loading /> : children}
        </AuthContex.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContex)
}

