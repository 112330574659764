import { useQuery } from 'react-query'
import axios, {AxiosError} from 'axios'
import {useEffect} from "react";
import {routes} from "../../utils";

/**
 *
 * @param endpoint
 * @param onSuccess
 * @param onError
 * @param params
 * @param dataKey In nearly all cases, the JSON response contains a data key which contains the data you need. However,
 *                it may be the case that you need another key.
 * @returns {{isLoading: boolean, reload: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>)) => Promise<QueryObserverResult<TQueryFnData, unknown>>, data: TQueryFnData, error: (string|*)}}
 */
export default function useGet({ endpoint, onSuccess, onError, params = {}, dataKey = 'data' }) {
    const { data, isLoading, error, refetch } = useQuery(
        // The query will automatically update when this key changes
        [endpoint, JSON.stringify(params)],
        async () => await axios.get(endpoint, { params })
            .then(({ data }) => data.hasOwnProperty(dataKey) ? data[dataKey] : data),
        { enabled: !!endpoint, retry: 2, onSuccess, onError, cacheTime: 0 }
    );

    useEffect(() => {
        if (endpoint && error?.response?.status === 401) {
            window.location.href = routes.login;
        }
    }, [error, endpoint]);

    return {
        data,
        isLoading,
        error: error instanceof AxiosError && error.response.status === 403
            ? 'You do not have access.'
            : error?.response?.data?.message || error?.message,
        reload: refetch
    }
}
