import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {inputClasses} from "../../forms/inputClasses";
import {ErrorMessage, useField} from "formik";

const PaidSectionPropTypes = {
    name: PropTypes.string.isRequired,
    currencyName: PropTypes.string.isRequired,
};

export default function PaidSection({ name, currencyName }) {
    const [{ value: amounts },, { setValue }] = useField(name);
    const [{ value: currencies }] = useField(currencyName);

    return (
        <>
            {currencies.map((currency, idx) => {
                const amountName = `${name}.${idx}`;
                return (
                    <div key={currency}>
                        <div className="d-flex align-items-center justify-content-center border mb-2 rounded-2">
                            <input
                                className={`${inputClasses.numberInput} border-0`}
                                name={amountName}
                                value={amounts[idx]}
                                onChange={(e) => {
                                    const newValue = [...amounts];
                                    newValue[idx] = e.target.value;
                                    setValue(newValue);
                                }}
                                placeholder="Enter amount"
                                style={{ maxWidth: 250 }}
                                autoFocus
                            />
                            <span className="h3 px-2 ">{currency}</span>
                        </div>
                        <span className="text-danger">
                            <ErrorMessage name={amountName} />
                        </span>
                  </div>
                );
            })}
        </>
    );
}

PaidSection.propTypes = PaidSectionPropTypes;
