import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ListedInformationPropTypes = {}

const ListedInformationDefaultProps = {}

export default function ListedInformation({ title, children }) {
    return (
        <Container>
            <Row>
                <Col sm={3}>
                    <h2>{title}</h2>
                </Col>
                <Col sm={9}>
                    {children}
                </Col>
            </Row>
            <hr />
        </Container>
    );
}

ListedInformation.propTypes = ListedInformationPropTypes;
ListedInformation.defaultProps = ListedInformationDefaultProps;


ListedInformation.Item = ({ title = '', children, showLine = true }) => (
    <>
        {title && <h6>{title}</h6>}
        <p>{children}</p>
        {showLine && <hr />}
    </>
)
