import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Description.module.scss';

const DescriptionPropTypes = {
    className: PropTypes.string,
}

const DescriptionDefaultProps = {
    className: '',
}

export default function Description({ children, className }) {
    return (
        <p className={cx({
            'gray-700': true,
            [className]: true,
            [styles.Description]: true,
        })}>
            {children}
        </p>
    );
}

Description.propTypes = DescriptionPropTypes;
Description.defaultProps = DescriptionDefaultProps;
