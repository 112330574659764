import React from 'react';
import {useParams} from "react-router-dom";
import {BackendForm, FloatingLabelField} from "../../components/forms";
import {Pages} from "../../components/layout";
import {routes} from "../../utils";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/PageHeader";
import useGet from "../../components/hooks/useGet";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import ImageUploader from "../../components/forms/ImageUploader";
import EntityTimestamps from "../../components/EntityTimestamps";
import PanelSection from "../../components/layout/PanelSection";
import {routeLabels} from "../../utils/routes";

export default function ItemGroup() {
    const { itemGroupId } = useParams();
    const navigate = useNavigate();
    const isNew = itemGroupId === 'new';
    const { data: itemGroup, isLoading: isLoadingField, error: fieldError } = useGet({ endpoint: !isNew ? `/item-groups/${itemGroupId}?_with[]=image`: '' });

    return (
        <Pages.SideBar
            inverse
            sidebar={<PanelSection><EntityTimestamps entity={itemGroup} /></PanelSection>}
            sidebarHasTopMargin={false}
            breadcrumbs={[
                { href: routes.dashboard, label: routeLabels.dashboard },
                { href: routes.itemGroups, label: routeLabels.itemGroups },
                { href: '', label: isNew ? 'Creating item group': itemGroup?.id },
            ]}
        >
            {isLoadingField && <Loading />}
            {fieldError && <Alert variant="danger">{fieldError}</Alert>}
            {(isNew || itemGroup) && (
                <BackendForm
                    targetEndpoint={isNew ? '/item-groups' : `/item-groups/${itemGroupId}`}
                    targetMethod={isNew ? 'POST': 'PUT'}
                    initialValues={itemGroup || {
                        name: '',
                        image: {},
                    }}
                    enableReinitialize
                    onSuccess={(resp) => {
                        const id = resp?.data?.id;
                        navigate(id ? routes.itemGroup(id) : routes.itemGroups)
                    }}
                >
                    <PageHeader
                        header={isNew ? 'Creating new item group' : `Editing: ${itemGroup?.name}`}
                        backUrl={routes.itemGroups}
                    />
                    <FloatingLabelField name="name" label="Item group name" />
                    {itemGroupId && <ImageUploader name="image" label="Kiosk file" type="App\Models\ItemGroup" typeId={itemGroupId}  />}
                </BackendForm>
            )}
        </Pages.SideBar>
    );
}

